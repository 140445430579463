import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import Good from '@/components/icons/smiley/Good'
import Insufficient from '@/components/icons/smiley/Insufficient'
import ToImprove from '@/components/icons/smiley/ToImprove'
import VeryGood from '@/components/icons/smiley/VeryGood'

export const STEP_CONFIG = [
  {
    id: 1,
    title:
      "Comment jugez-vous la fluidité de la communication avec tous les membres de l'équipe ?",
    key: 'COM',
  },
  {
    id: 2,
    title:
      "Comment évaluez-vous l'accompagnement de Hello Pomelo dans le conseil, les solutions proposées et la compréhension de vos besoins ?",
    key: 'UND',
  },
  {
    id: 3,
    title:
      "L'engagement de l'équipe est-il à la hauteur de vos attentes et vous permet-il d'aborder les prochaines semaines du projet avec confiance ?",
    key: 'REA',
  },
  {
    id: 4,
    title:
      'Êtes-vous satisfait des livrables (maquettes, documentation, produit final) ?',
    key: 'DEL',
  },
  {
    id: 5,
    title:
      'Vous sentez-vous en phase avec la roadmap et les délais prévus ? (Remarques ou inquiétudes ?)',
    key: 'DEA',
  },
]

export const TIMELINE_DATA = [
  {
    id: 1,
    title: 'Communication',
    value: 'COM',
  },
  {
    id: 2,
    title: 'Conseil et compréhension des enjeux',
    value: 'UND',
  },
  {
    id: 3,
    title: 'Réactivité et anticipation',
    value: 'REA',
  },
  {
    id: 4,
    title: 'Qualité des livrables',
    value: 'DEL',
  },
  {
    id: 5,
    title: 'Respects des délais',
    value: 'DEA',
  },
]

export const OPINION_LIST = [
  {
    id: 4,
    desc: 'Très satisfait',
    icon: <VeryGood />,
  },
  {
    id: 3,
    desc: 'Satisfait',
    icon: <Good />,
  },
  {
    id: 2,
    desc: 'Peu satisfait',
    icon: <ToImprove />,
  },
  {
    id: 1,
    desc: 'Pas du tout satisfait',
    icon: <Insufficient />,
  },
]

export const pastSectionAtom = atom<string[]>([])
export const currentMainStepAtom = atom<number>(0)
export const stepDataAtom = atom<
  { key: string; grade: number; comment: string }[]
>([])
export const currentStepKey = atom<string>('COM')
export const hasFormInteractionAtom = atom(false) // USE TO WATCH USER INTERACTION FOR CANCEL ROUTING
export const isActiveCancelModalAtom = atom(false)
export const nextPathAtom = atom('')
export const mainStep = atom([0]) // it state to 3 steps

export default function useClientSatisfaction() {
  const [isFirstStep, setIsFirstStep] = useState(true)
  const setCurrentStepKey = useSetAtom(currentStepKey)
  const currentStepKeyState = useAtomValue(currentStepKey)
  const setStepData = useSetAtom(stepDataAtom)
  const stepData = useAtomValue(stepDataAtom)
  const [pastSectionState, setPastSectionState] = useAtom(pastSectionAtom)
  const [mainStepState, setMainStepState] = useAtom(mainStep)
  const [currentMainStep, setCurrentMainStep] = useAtom(currentMainStepAtom)

  const nextSectionProcess = (key: string, mainKey: number) => {
    if (mainKey === 0) {
      TIMELINE_DATA.forEach((_, index) => {
        if (index < TIMELINE_DATA.length) {
          const current = TIMELINE_DATA.find((item) => item.value === key)
          if (current) {
            const currentIndex = TIMELINE_DATA.indexOf(current)
            if (TIMELINE_DATA[currentIndex + 1]) {
              setCurrentStepKey(TIMELINE_DATA[currentIndex + 1].value)
            } else {
              setCurrentMainStep(1)
              setMainStepState((prev) => Array.from(new Set([...prev, 1])))
            }
          }
          if (!pastSectionState.includes(key)) {
            setPastSectionState((prev) => [...prev, key])
          }
        }
      })
    }
    if (mainKey === 1) {
      setMainStepState((prev) => Array.from(new Set([...prev, 2])))
      setCurrentMainStep(2)
    }
  }
  const prevSectionProcess = (key: string, mainKey: number) => {
    if (mainKey === 0) {
      TIMELINE_DATA.forEach((_, index) => {
        if (index > 0) {
          const current = TIMELINE_DATA.find((item) => item.value === key)
          if (current) {
            const currentIndex = TIMELINE_DATA.indexOf(current)
            setCurrentStepKey(TIMELINE_DATA[currentIndex - 1].value)
          }
        }
      })
    }
    if (mainKey === 1) {
      setCurrentStepKey((TIMELINE_DATA.at(-1) as any).value)
    }
  }

  const handleNextSection = (
    payload: {
      key: string
      grade: number
      comment: string
    },
    mainKey: number
  ) => {
    const isStepDataAlreadyExists = !!stepData.find(
      (item) => item.key === payload.key
    )
    if (isStepDataAlreadyExists) {
      setStepData((prev) =>
        [...prev].map((item) => {
          if (payload.key === item.key) {
            return payload
          }
          return item
        })
      )
    } else {
      setStepData((prev) => [...prev, payload])
    }

    nextSectionProcess(payload.key, mainKey)
  }
  const handlePrevSection = (key: string, mainKey: number) => {
    prevSectionProcess(key, mainKey)

    if (mainKey !== 0) {
      setCurrentMainStep(mainKey - 1)
    }
  }

  useEffect(() => {
    const current = TIMELINE_DATA.find(
      (item) => item.value === currentStepKeyState
    )
    if (current) {
      const currentIndex = TIMELINE_DATA.indexOf(current)
      setIsFirstStep(currentIndex === 0)
    }
  }, [currentStepKeyState])

  return {
    handleNextSection,
    handlePrevSection,
    currentStepKeyState,
    isFirstStep,
    stepData,
    pastSectionAtom,
    pastSectionState,
    mainStepState,
    currentMainStep,
  }
}
