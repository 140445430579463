import PenIcon from '../icons/PenIcon'
import clsx from 'clsx'
import { IRelease } from '@/types/IRelease'
import useDirOwnerProdAccess from '@/hooks/use-dir-owner-prod-access'
interface Props {
  release: IRelease
  className: string
  setSelectedRelease: (release: IRelease) => void
}

function formatDate(currentDate: Date) {
  const date = currentDate
  const formattedDate = date.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
  const formattedTime = date.toLocaleTimeString('fr-FR', {
    hour: '2-digit',
    minute: '2-digit',
  })
  const fullDateTime = `${formattedDate} ${formattedTime}`
  return fullDateTime
}

const useReleaseConverter = (rawString: string) =>
  rawString.split('\n#').map((section) => {
    const [title, ...items] = section.split('\n')
    return {
      title: title.startsWith('#') ? title : `${title}`, // To handle first section
      items: items.filter((item) => item.trim() !== ''), // Remove empty items
    }
  })

export default function ReleaseDetailItem({
  release,
  className,
  setSelectedRelease,
}: Readonly<Props>) {
  const sections = useReleaseConverter(release.notes)
  const { hasAccess } = useDirOwnerProdAccess()

  return (
    <div id={release.id} className={clsx('bg-white border rounded', className)}>
      {/* HEADING */}
      <div className="flex justify-between items-center border-b px-8 py-6">
        <div className="flex flex-col gap-y-2">
          <p className="text-primary-dark font-semibold text-xl">
            {release.number}
          </p>
          <p className="text-neutral-darker/60 text-xs">
            {formatDate(new Date(release.modified_at))}
          </p>
        </div>
        {hasAccess ? (
          <button
            id="btn-edit-release"
            onClick={() => setSelectedRelease(release)}
            className="transition-all hover:cursor-pointer [&_path]:hover:stroke-neutral-darker hover:text-neutral-darker flex items-center gap-x-2 text-sm text-neutral-darker/60"
          >
            Modifier <PenIcon className="[&_path]:stroke-neutral-darker/60" />
          </button>
        ) : (
          ''
        )}
      </div>
      {/* BODY */}
      <div className="px-8 py-6">
        {sections.map((section) => (
          <div key={section.title}>
            {section.title && (
              <h5 className="text-base font-semibold text-primary-dark mb-4">
                {section.title.startsWith('#')
                  ? section.title.split('#')[1]
                  : section.title}
              </h5>
            )}
            <ul className="mb-4 list-disc pl-6">
              {section.items.map((item) => (
                <li
                  className="text-primary-dark text-sm font-normal"
                  key={item}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}
