import HeadingTitle from '@/components/ui/heading-title'
import TicketSummaryItem from '@/components/ticket-to-test/TicketSummaryItem'
import TicketSummaryDetail from '@/components/ticket-to-test/TicketSummaryDetail'
import ChevronLeft from '@/components/icons/ChevronLeft'
import ChevronRight from '@/components/icons/ChevronRight'
import FilterTabs from '@/components/ticket-to-test/FilterTabs'
import { useTicketStats, STATUS } from '@/hooks/use-ticket-stats'
import { TAB_LABELS, PRIORITY_STATUS, NOTIFICATION } from '@/configs/constants'
import { cancelTicketAction, getTicketToTest } from '@/services/api-client'
import { Button } from '@/components/ui/button'
import { IIssue } from '@/types/ITicketToTest'
import { useEffect, useState } from 'react'
import SearchIcon from '@/components/icons/SearchIcon'
import { Input } from '@/components/ui/input'
import SortIcon from '@/components/icons/SortIcon'
import NotAllowedIcon from '@/components/icons/NotAllowedIcon'
import ActiveFilterTag from '@/components/ticket-to-test/ActiveFilterTag'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import Notification from '@/components/ticket-to-test/Notification'
import { LoaderCircleIcon } from 'lucide-react'
import AllTicketTested from '@/components/ticket-to-test/AllTicketTested'

const options = [
  { id: 'Urgente', label: 'Urgente' },
  { id: 'Forte', label: 'Forte' },
  { id: 'Moyenne', label: 'Moyenne' },
  { id: 'Faible', label: 'Faible' },
]
type NotificationType = (typeof NOTIFICATION)[keyof typeof NOTIFICATION]

export default function TicketToTest() {
  const [currentPage, setCurrentPage] = useState(1)
  const [ticketList, setTicketList] = useState<IIssue[]>([])
  const [activeTicketId, setActiveTicketId] = useState<string | null>()
  const [ticketDetail, setTicketDetail] = useState<IIssue>()
  const [ticketToTest, setTicketToTest] = useState<number>(0)
  const [ticketRefused, setTicketRefused] = useState<number>(0)
  const [ticketAccepted, setTicketAccepted] = useState<number>(0)
  const [currentTab, setCurrentTab] = useState<string>('')
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [dateSort, setDateSort] = useState<string>('')
  const [currentTickets, setCurrentTickets] = useState<IIssue[]>()
  const [selectedPriorities, setSelectedPriorities] = useState<string[]>([])
  const [currentTicketSubmitted, setCurrentTicketSubmitted] = useState('')
  const [notificationType, setNotificationType] = useState('')
  const [loadCancel, setLoadCancel] = useState(false)
  const [isCancel, setIsCancel] = useState(true)
  const [lastAction, setLastAction] = useState<NotificationType>(
    NOTIFICATION.success
  )
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [isLoading, setIsLoading] = useState(true)

  const stats = useTicketStats(ticketList)

  const fetchTicketToTestData = async () => {
    try {
      const { data } = await getTicketToTest()
      setTicketList(data)
      if (data && data.length > 0) {
        setActiveTicketId(data[0].key)
        setTicketDetail(data[0])
      }
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoading(false)
    }
  }

  const filterTickets = () => {
    let filteredList = ticketList

    // apply filter tab
    if (currentTab) {
      switch (currentTab) {
        case TAB_LABELS.ACCEPTED:
          filteredList = filteredList.filter(
            (item) => item.fields.status.name === STATUS.accepted
          )
          break
        case TAB_LABELS.REFUSED:
          filteredList = filteredList.filter(
            (item) => item.fields.status.name === STATUS.refused
          )
          break
        case TAB_LABELS.TO_TEST:
          filteredList = filteredList.filter(
            (item) => item.fields.status.name === STATUS.toTest
          )
          break
      }
    }

    // apply search input
    if (searchQuery) {
      filteredList = filteredList.filter(
        (item) =>
          item.key.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.fields.summary.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }

    // Apply priority filter
    if (selectedPriorities.length > 0) {
      const enSelectedPrioritites = selectedPriorities.map((item) =>
        PRIORITY_STATUS[item].toLowerCase()
      )

      filteredList = filteredList.filter((item) =>
        enSelectedPrioritites.includes(item.fields.priority.name.toLowerCase())
      )
    }

    // Apply date sort
    if (dateSort) {
      filteredList.sort((a, b) => {
        const dateA = new Date(a.fields.created).getTime()
        const dateB = new Date(b.fields.created).getTime()
        return dateSort === 'desc' ? dateB - dateA : dateA - dateB
      })
    }

    // Update the current ticket and paginator
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    setCurrentTickets(filteredList.slice(startIndex, endIndex))

    // If the filtered list is not empty, select the first ticket
    if (filteredList.length > 0) {
      const firstVisibleTicket = filteredList[startIndex]
      setActiveTicketId(firstVisibleTicket.key)
      setTicketDetail(firstVisibleTicket)
    }
  }

  const handleTabFilter = (key: string) => {
    setCurrentTab(key)
    setCurrentPage(1) // Reset page when switching tabs
  }

  const handlePriorityChange = (priority: string) => {
    setSelectedPriorities((prev) => {
      if (prev.includes(priority)) {
        return prev.filter((p) => p !== priority)
      }
      return [...prev, priority]
    })
    setCurrentPage(1)
  }

  const handleDateSortChange = (value: string) => {
    setDateSort(value)
  }
  const handleFilterBySubjetOrKey = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value)
    setCurrentPage(1) //Reset page when searching
  }

  const handleActionSubmit = (ticket: { key: string; type: string }) => {
    setIsCancel(true)
    setCurrentTicketSubmitted(ticket.key)
    setNotificationType(ticket.type)
    setLastAction(ticket.type as NotificationType)

    setCurrentTickets((prevTickets) => {
      if (!prevTickets) return undefined

      return prevTickets.map((prevTicket) => {
        if (prevTicket.key === ticket.key) {
          return {
            ...prevTicket,
            fields: {
              ...prevTicket.fields,
              status: {
                ...prevTicket.fields.status,
                name:
                  ticket.type === NOTIFICATION.success
                    ? STATUS.accepted
                    : STATUS.refused,
              },
            },
          }
        }
        return prevTicket
      })
    })
    setTicketDetail((prevTicket) => {
      if (!prevTicket) return undefined

      return {
        ...prevTicket,
        fields: {
          ...prevTicket.fields,
          status: {
            ...prevTicket.fields.status,
            name:
              ticket.type === NOTIFICATION.success
                ? STATUS.accepted
                : STATUS.refused,
          },
        },
      }
    })
    if (ticket.type === NOTIFICATION.success) {
      setTicketAccepted((prev) => prev + 1)
      if (ticketToTest !== 0) {
        setTicketToTest((prev) => prev - 1)
      }
    } else {
      setTicketRefused((prev) => prev + 1)
      if (ticketToTest !== 0) {
        setTicketToTest((prev) => prev - 1)
      }
    }
  }

  const handleTicketClick = (ticketId: string) => {
    setActiveTicketId(ticketId)
    const ticket = ticketList.find((ticket: IIssue) => ticket.key === ticketId)
    setTicketDetail(ticket)
  }

  const getTotalPages = () => {
    const filteredLength = currentTickets?.length ?? 0
    return Math.ceil(filteredLength / itemsPerPage)
  }

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1))
  }

  const handleNextPage = () => {
    const totalPages = getTotalPages()
    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
  }

  const applyFilters = () => {
    setCurrentPage(1)
    filterTickets()
  }

  useEffect(() => {
    fetchTicketToTestData()
  }, [])

  useEffect(() => {
    if (ticketList.length > 0) {
      setTicketToTest(
        ticketList.filter((item) => item.fields.status.name === STATUS.toTest)
          .length
      )
      setTicketRefused(
        ticketList.filter((item) => item.fields.status.name === STATUS.refused)
          .length
      )
      setTicketAccepted(
        ticketList.filter((item) => item.fields.status.name === STATUS.accepted)
          .length
      )
    }
  }, [ticketList])

  useEffect(() => {
    console.log(currentTab, 'tab')
    filterTickets()
  }, [
    currentTab,
    searchQuery,
    ticketList,
    currentPage,
    selectedPriorities,
    dateSort,
    itemsPerPage,
  ])

  useEffect(() => {
    setTicketToTest(stats.toTest)
    setTicketRefused(stats.refused)
    setTicketAccepted(stats.accepted)
  }, [stats])

  const handleRemoveDateSort = () => {
    setDateSort('')
    setCurrentPage(1)
  }

  const handleRemovePriorityFilter = () => {
    setSelectedPriorities([])
    setCurrentPage(1)
  }

  const cancelAction = async (type: string) => {
    try {
      setLoadCancel(true)
      const rejectionStatus =
        type === 'error' ? { unreject: true } : { unapprove: true }
      await cancelTicketAction(currentTicketSubmitted, rejectionStatus)
      if (type === NOTIFICATION.success) {
        setTicketAccepted((prev) => prev - 1)
        setTicketToTest((prev) => prev + 1)
      } else {
        setTicketRefused((prev) => prev - 1)
        setTicketToTest((prev) => prev + 1)
      }
    } catch (error) {
      throw new Error(`Unexpected error ${error}`)
    } finally {
      setNotificationType(NOTIFICATION.cancel)
      setIsCancel(false)
      setLoadCancel(false)
      setCurrentTickets((prevTickets) => {
        if (!prevTickets) return undefined

        return prevTickets.map((prevTicket) => {
          if (prevTicket.key === currentTicketSubmitted) {
            return {
              ...prevTicket,
              fields: {
                ...prevTicket.fields,
                status: {
                  ...prevTicket.fields.status,
                  name: STATUS.toTest,
                },
              },
            }
          }
          return prevTicket
        })
      })
      setTicketDetail((prevTicket) => {
        if (!prevTicket) return undefined

        return {
          ...prevTicket,
          fields: {
            ...prevTicket.fields,
            status: {
              ...prevTicket.fields.status,
              name: STATUS.toTest,
            },
          },
        }
      })
      // setCurrentTicketSubmitted('')
    }
  }

  if (isLoading) {
    return (
      <div className="h-full flex items-center w-full justify-center">
        <LoaderCircleIcon className="animate-spin w-8 h-8" />
      </div>
    )
  }

  return (
    <div className="flex flex-col h-[calc(100%-3rem)]">
      <HeadingTitle label="Tickets à tester" />

      <div className="mb-4 pb-3 border-b-2 flex items-center justify-between [&_ul]:border-none [&_li]:p-0 [&_li:after]:bottom-[-20px]">
        <FilterTabs
          additionalStatusData={[
            { status: TAB_LABELS.TO_TEST, count: ticketToTest ?? 0 },
            { status: TAB_LABELS.REFUSED, count: ticketRefused ?? 0 },
            { status: TAB_LABELS.ACCEPTED, count: ticketAccepted ?? 0 },
          ]}
          setFilterStatus={handleTabFilter}
        />
        <div className="inline-flex gap-5 items-center">
          <div className="[&_svg]:w-5 [&_svg]:h-5 [&_path]:stroke-primary-dark hover:cursor-pointer">
            <DropdownMenu>
              <DropdownMenuTrigger className="flex items-center h-full">
                <SortIcon />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <div className="p-4 flex gap-2 flex-col">
                  <p className="text-base text-primary-dark">Filtrer par</p>
                  <p className="uppercase text-xs text-neutral-darker after:contents[' '] relative after:absolute after:w-[75%] after:right-0 after:block after:h-1 after:bg-neutral-light after:top-1">
                    Priorité
                  </p>

                  {options.map((option) => (
                    <div
                      key={option.id}
                      className="inline-flex items-center gap-2"
                    >
                      <Checkbox
                        id={option.id}
                        checked={selectedPriorities.includes(option.id)}
                        onCheckedChange={() => handlePriorityChange(option.id)}
                      />
                      <label
                        htmlFor={option.id}
                        className="text-primary-dark text-sm"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}

                  <p className="uppercase text-xs text-neutral-darker after:contents[' '] relative after:absolute after:w-[85%] after:right-0 after:block after:h-1 after:bg-neutral-light after:top-1">
                    Date
                  </p>
                  <div className="pb-2 border-b-2 border-neutral-dark">
                    <Select
                      value={dateSort}
                      onValueChange={handleDateSortChange}
                    >
                      <SelectTrigger id='select-order' className="w-[282px]" isClassic>
                        <SelectValue placeholder="Selectionner un filtre" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="desc">
                          Du plus récent au plus ancien
                        </SelectItem>
                        <SelectItem value="asc">
                          Du plus ancien au plus récent
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex items-center justify-end">
                    <DropdownMenuItem>
                      <Button
                        id='apply-btn'
                        className="px-4 py-2 text-white max-w-28"
                        onClick={applyFilters}
                      >
                        Appliquer
                      </Button>
                    </DropdownMenuItem>
                  </div>
                </div>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <Input
            id='filter-input-search'
            placeholder="Rechercher par sujet ou clé"
            onChange={handleFilterBySubjetOrKey}
            value={searchQuery}
            className="placeholder:text-[#717171] h-8 mr-1 max-w-[275px] placeholder:font-medium text-sm bg-white"
            prefixIcon={<SearchIcon />}
          />
        </div>
      </div>
      <div className="flex gap-2 items-center mb-4">
        {dateSort && (
          <ActiveFilterTag
            label={`Date: ${dateSort === 'desc' ? 'Du plus récent au plus ancien' : ' Du plus ancien au plus récent'}`}
            onRemove={() => handleRemoveDateSort()}
          />
        )}
        {selectedPriorities.length > 0 && (
          <ActiveFilterTag
            label={`Priorité: ${selectedPriorities.join(', ')}`}
            onRemove={() => handleRemovePriorityFilter()}
          />
        )}
      </div>

      {(ticketToTest === 0 && currentTab === TAB_LABELS.TO_TEST) ||
      ticketList.length === 0 ? (
        <AllTicketTested />
      ) : (
        <div className="grid grid-cols-6 gap-4 flex-grow  pb-6">
          <div className="col-span-2 rounded-sm overflow-hidden flex flex-col">
            <div className="flex-grow">
              {currentTicketSubmitted && notificationType && (
                <Notification
                  onCancel={() => cancelAction(notificationType)}
                  type={notificationType}
                  ticket={currentTicketSubmitted}
                  isLoad={loadCancel}
                  showCancel={isCancel}
                  previousAction={lastAction}
                />
              )}
              {currentTickets?.map((ticket: IIssue) => (
                <TicketSummaryItem
                  key={ticket.id}
                  ticketKey={ticket.key}
                  created={ticket.fields.created}
                  label={ticket.fields.summary}
                  isActive={activeTicketId === ticket.key}
                  count={ticket.fields.comment.total}
                  handleClick={() => handleTicketClick(ticket.key)}
                  comments={ticket.fields.comment.comments}
                  prioImg={ticket.fields.priority.iconUrl}
                />
              ))}
              {!currentTickets || currentTickets.length === 0 ? (
                <div className="bg-white rounded-sm border border-neutral-dark flex items-center justify-center h-28">
                  <p className="inline-flex gap-3 items-center">
                    <span>
                      <NotAllowedIcon />
                    </span>
                    <span>Pas de résultat</span>
                  </p>
                </div>
              ) : (
                ''
              )}
              {/* Pagination */}
              <div className="flex justify-between items-center p-4">
                <div className="text-sm text-gray-600 flex items-center gap-x-1">
                  Page{' '}
                  <Select
                    value={itemsPerPage + ''}
                    onValueChange={(e) => setItemsPerPage(Number(e))}
                  >
                    <SelectTrigger
                      id='pagination-count-btn'
                      isClassic
                      className="w-auto bg-white h-7 flex gap-x-2 items-center [&_svg]:w-5 [&_svg]:h-5"
                    >
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="10">1-10</SelectItem>
                        <SelectItem value="30">1-30</SelectItem>
                        <SelectItem value="50">1-50</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>{' '}
                  sur {getTotalPages()}
                </div>
                <div className="inline-flex gap-4">
                  <Button
                    id='prev-page-btn'
                    variant="outline"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className="w-8 h-8 p-0"
                  >
                    <ChevronLeft />
                  </Button>

                  <Button
                    id='next-page-btn'
                    variant="outline"
                    onClick={handleNextPage}
                    disabled={currentPage === getTotalPages()}
                    className="w-8 h-8 p-0"
                  >
                    <ChevronRight />
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-4">
            <TicketSummaryDetail
              title={ticketDetail?.fields.summary as string}
              priorityStatus={ticketDetail?.fields.priority.name as string}
              ticketStatus={ticketDetail?.fields.status.name as string}
              ticketKey={ticketDetail?.key as string}
              date={new Date(ticketDetail?.fields.created as string)}
              emitTicketkey={handleActionSubmit}
              prioImg={ticketDetail?.fields.priority.iconUrl!}
              epic={ticketDetail?.fields.parent?.fields.summary!}
              sprint={
                ticketDetail?.fields?.customfield_10020
                  ? ticketDetail?.fields?.customfield_10020[0].name
                  : ''
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}
