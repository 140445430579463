import { CheckCircle2 } from 'lucide-react'
const AllTicketTested = () => {
  return (
    <div className="flex items-center justify-center h-full w-full">
      <div className="flex flex-col justify-center items-center gap-4">
        <div className="bg-success/10 rounded w-12 h-12 inline-flex items-center justify-center [&_svg]:stroke-success">
          <CheckCircle2 />
        </div>
        <div className="text-primary-dark text-2xl">
          Tous les tickets ont été tester
        </div>
      </div>
    </div>
  )
}

export default AllTicketTested
