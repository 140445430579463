import ColoredPlusIcon from '@/components/icons/ColoredPlusIcon'
import CrossIcon from '@/components/icons/CrossIcon'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import SwitchButton from '@/components/ui/switch-button'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

import {
  CATEGORY_LIST,
  PRIORITY_LIST,
  TOAST_ERROR_MSG,
} from '@/configs/constants'
import { Textarea } from '@/components/ui/textarea'
import { Separator } from '@/components/ui/separator'
import { useEffect, useState } from 'react'
import { createRequest } from '@/services/api-client'
import { useToast } from '@/hooks/use-toast'
import CancelModalContent from '@/components/modal/cancel-modal-content'
import { Paperclip } from 'lucide-react'
import { Label } from '@/components/ui/label'

const formSchema = z.object({
  title: z
    .string()
    .min(1, {
      message: 'Le titre doit contenir au moin 1 caractère.',
    })
    .max(128, {
      message: 'Le titre doit pas depasser 128 caractères.',
    }),
  summary: z.string().min(1, {
    message: 'La description doit contenir au moin 1 caractère.',
  }),
  category: z.string().min(1, 'Ajouter au moin une categorie.'),
  environment: z.boolean(),
  priority: z.string().min(1, 'Ajouter au moin une priorité'),
})

export default function RequestForm({
  closeModal,
  isInteractOutside,
}: Readonly<{ closeModal: Function; isInteractOutside?: boolean }>) {
  const defaultValues = {
    title: '',
    summary: '',
    category: CATEGORY_LIST[0].value,
    environment: true, // true means "Production" and false => "Pre-prod"
    priority: '',
  }
  const [hasChanges, setHasChanges] = useState(false)
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  useEffect(() => {
    const subscription = form.watch((values) => {
      setHasChanges(JSON.stringify(values) !== JSON.stringify(defaultValues))
    })
    return () => subscription.unsubscribe()
  }, [form])

  const { toast } = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const [isOpenCancelCreaction, setIsOpenCancelCreaction] = useState(false)
  const [currentPicture, setCurrentPicture] = useState()
  const convertEnvField = (str: boolean) => {
    enum Environment {
      Production = 'Production',
      PreProd = 'Pre-Prod',
    }
    return str ? Environment.Production : Environment.PreProd
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const payload = {
      ...values,
      files: currentPicture ?? null,
      environment: convertEnvField(values.environment),
    }
    try {
      setIsLoading(true)
      await createRequest(payload)
      closeModal(values.title)
    } catch (error) {
      toast(TOAST_ERROR_MSG)
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoading(false)
    }
  }

  const closeAllActiveModal = () => {
    setIsOpenCancelCreaction(false)
    closeModal()
  }

  useEffect(() => {
    if (isInteractOutside) {
      if (hasChanges) {
        setIsOpenCancelCreaction(true)
      } else {
        closeAllActiveModal()
      }
    }
  }, [isInteractOutside])

  return (
    <div>
      <DialogDescription />
      <DialogTitle />
      <button
        onClick={() =>
          hasChanges ? setIsOpenCancelCreaction(true) : closeAllActiveModal()
        }
        className="absolute top-5 right-9 hover:cursor-pointer opacity-70 transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
      >
        <CrossIcon className="h-5 w-5" />
      </button>
      <div>
        <div className="flex justify-start items-center px-0 gap-x-2">
          <div className="p-1 rounded-md bg-primary-light/10 w-auto">
            <ColoredPlusIcon />
          </div>
          <span className="text-base font-medium text-primary-dark">
            Ajout d'une demande
          </span>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="pt-0 px-0 space-y-8 [&>div]:!mt-4">
              <div className="max-w-sm">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-primary-dark">Titre</FormLabel>
                      <FormControl>
                        <Input placeholder="Ajoutez un titre" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="category"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-primary-dark">
                        Type de demande
                      </FormLabel>
                      <Select onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Tâche" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {CATEGORY_LIST.map((item) => (
                            <SelectItem key={item.value} value={item.value}>
                              {item.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="priority"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-primary-dark">
                        Priorité
                      </FormLabel>
                      <Select onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Selectionnez" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {PRIORITY_LIST.map((item) => (
                            <SelectItem key={item} value={item}>
                              {item === 'Urgent' ? 'Urgente' : item}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="environment"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-primary-dark">
                        Environnement concerné
                      </FormLabel>
                      <FormControl>
                        <SwitchButton
                          id="request-switch-env"
                          leftValue="Production"
                          rightValue="Pre-prod"
                          onChange={field.onChange}
                          light
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="summary"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-primary-dark">
                      Description
                    </FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Textarea
                          {...field}
                          placeholder="Ajoutez les spécifications de la demande ..."
                          rows={7}
                        />
                        <Button
                          type="button"
                          size={'sm'}
                          variant={'ghost'}
                          className="absolute right-2 bottom-1"
                        >
                          <Label
                            htmlFor="picture"
                            id="attachment-desc"
                            className="hover:cursor-pointer"
                          >
                            <Paperclip className="text-neutral-darker/60 w-5 h-5" />
                          </Label>
                          <input
                            onChange={(e) =>
                              setCurrentPicture((e.target.files as any)[0])
                            }
                            className="hidden"
                            id="picture"
                            type="file"
                            accept="audio/*|video/*|image/*|application/*|text/*"
                          />
                        </Button>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Separator className="absolute left-0 w-full" />
            <div className="flex justify-end items-center h-20 ">
              <Button
                variant={'ghost'}
                className="text-neutral-darker hover:bg-transparent"
                onClick={() =>
                  hasChanges
                    ? setIsOpenCancelCreaction(true)
                    : closeAllActiveModal()
                }
                type="button"
                id="cancel-btn"
              >
                Annuler
              </Button>
              <Button id="create-btn" type="submit" isLoading={isLoading}>
                Créer
              </Button>
            </div>
          </form>
        </Form>
      </div>

      {/* CONFIRMATION CLOSE DIALOG */}
      <Dialog
        open={!!isOpenCancelCreaction}
        onOpenChange={() => setIsOpenCancelCreaction(false)}
      >
        <DialogContent className="max-w-[480px]">
          <CancelModalContent
            validationString="Ignorer la demande"
            onCancel={() => closeAllActiveModal()}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
