import SprintDuration from '@/components/dashboard/SprintDuration'
import GoalIcon from '@/components/icons/Goal'
import { Badge } from '@/components/ui/badge'
import HeadingTitle from '@/components/ui/heading-title'
interface SprintInfoType {
  startDate: Date
  endDate: Date
  name: string
  goal: string
}
export default function SprintInProgressHeader({
  sprintInfo,
}: Readonly<{
  sprintInfo: SprintInfoType
}>) {
  return (
    <>
      <HeadingTitle containerClassName="flex flex-row items-center gap-x-2">
        <span className="text-xl font-semibold text-primary-dark">
          Sprint en cours
        </span>{' '}
        <Badge className="bg-primary-dark shadow-none rounded-full font-normal">
          {sprintInfo?.name}
        </Badge>
      </HeadingTitle>
      <div className="pt-6">
        <div className="bg-white border rounded-md flex justify-between items-center px-8 py-6">
          <div className="flex items-start gap-x-2">
            <button className="bg-primary-light/10 w-8 h-8 shadow-none rounded-md p-1">
              <GoalIcon />
            </button>
            <div className="flex items-start gap-x-2 flex-col gap-y-2 [&_p]:text-start">
              <p className="font-semibold">Objectif du sprint :</p>
              <p className="text-xs">{sprintInfo?.goal}</p>
            </div>
          </div>
          {sprintInfo.startDate && sprintInfo.endDate && (
            <SprintDuration
              startDate={new Date(sprintInfo?.startDate).toString()}
              endDate={new Date(sprintInfo?.endDate).toString()}
            />
          )}
        </div>
      </div>
    </>
  )
}
