import { Card, CardContent } from '@/components/ui/card'
import { format, parseISO } from 'date-fns'
import { fr } from 'date-fns/locale'
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts'

interface IDataPoint {
  date: string
  value: number
}

interface ITimeSeriesChartProps {
  data?: IDataPoint[]
  value: number
  unit: string
}

const defaultData: IDataPoint[] = [
  { date: '2024-07-07T23:30:00', value: 0 },
  { date: '2024-07-08T06:00:00', value: 100 },
  { date: '2024-07-08T12:30:00', value: 2 },
  { date: '2024-07-08T19:00:00', value: 8 },
  { date: '2024-07-08T23:30:00', value: 5 },
  { date: '2024-07-09T06:00:00', value: 4 },
  { date: '2024-07-09T12:30:00', value: 3 },
  { date: '2024-07-09T19:00:00', value: 2 },
  { date: '2024-07-09T23:30:00', value: 3 },
  { date: '2024-07-10T06:00:00', value: 55 },
  { date: '2024-07-10T12:30:00', value: 80 },
  { date: '2024-07-10T19:00:00', value: 15 },
]

const TimeSeriesChart: React.FC<ITimeSeriesChartProps> = ({
  data = defaultData,
  value = 0,
  unit,
}) => {
  const getTickIndices = (dataLength: number) => {
    if (dataLength <= 12) return Array.from({ length: dataLength }, (_, i) => i)

    const step = Math.floor(dataLength / 11)
    return Array.from({ length: 12 }, (_, i) => i * step).map((index) =>
      Math.min(index, dataLength - 1)
    )
  }

  const getMaxValue = () => {
    return unit === '%' ? 100 : value
  }
  const getLineColor = (value: number) => {
    if (value >= 80) return '#D9306B' // red
    if (value >= 60) return '#FA8334' // Orange
    return '#0BB54F' //green
  }

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload?.length) {
      return (
        <Card className="border border-muted shadow-lg">
          <CardContent className="p-2">
            <div className="text-sm">
              <div className="text-muted-foreground">
                {format(parseISO(label), 'd MMM, yyyy HH:mm', { locale: fr })}
              </div>
              <div className="text-foreground font-medium">
                {payload[0].value ? payload[0].value : 0}
                {unit}
              </div>
            </div>
          </CardContent>
        </Card>
      )
    }
    return null
  }

  const renderXAxisTick = (props: any) => {
    const { x, y, payload } = props
    const date = parseISO(payload.value)

    const formattedDate = format(date, 'd MMM', { locale: fr })
    const formattedTime = format(date, 'HH:mm', { locale: fr })

    return (
      <g transform={`translate(${x},${y})`} textAnchor="middle">
        <text dy={16} fontSize={12} fill="#717171">
          {formattedDate}
        </text>
        <text dy={30} fontSize={12} fill="#717171">
          {formattedTime}
        </text>
      </g>
    )
  }

  return (
    <div className="max-h-[180px] w-full">
      <ResponsiveContainer width="100%" height={150}>
        <LineChart
          accessibilityLayer
          height={180}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid stroke="#F6F6F6" strokeWidth={1} />
          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            ticks={getTickIndices(data.length).map((index) => data[index].date)}
            tickFormatter={(str) => {
              const date = parseISO(str)
              return format(date, 'd MMM, HH:mm', { locale: fr })
            }}
            angle={-45}
            textAnchor="end"
            height={70}
            tick={renderXAxisTick}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            tickFormatter={(value) => `${value}${unit}`}
            domain={[0, getMaxValue()]}
            tick={{ fontSize: 12 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="value"
            stroke={getLineColor(value)}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default TimeSeriesChart
