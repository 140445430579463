import {
  hasFormInteractionAtom,
  isActiveCancelModalAtom,
  nextPathAtom,
} from '@/atom/client-satisfaction'
import { ROUTES } from '@/configs/route-naming'
import { useAtom, useAtomValue } from 'jotai'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function useFeedbackCancellation() {
  const navigate = useNavigate()
  const [hasFormInteractionInFeedBack, setHasFormInteractionInFeedBack] =
    useAtom(hasFormInteractionAtom)
  const [isActiveCancelModal, setIsActiveCancelModal] = useAtom(
    isActiveCancelModalAtom
  )
  const [nextPath, setNextPath] = useAtom(nextPathAtom)
  const userWantQuit = (e: any, path: string) => {
    if (
      location.pathname === ROUTES.PROTECTED.ADD_FEEDBACK &&
      hasFormInteractionInFeedBack
    ) {
      setIsActiveCancelModal(true)
      setNextPath(path)
      e.preventDefault()
    }
  }
  const acceptCancellation = () => {
    setIsActiveCancelModal(false)
    setHasFormInteractionInFeedBack(false)
    navigate(nextPath)
  }
  return {
    userWantQuit,
    setIsActiveCancelModal,
    acceptCancellation,
    isActiveCancelModal,
  }
}
