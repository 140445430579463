import { useEffect, useRef, useState, useCallback } from 'react'
import { Button } from '../ui/button'
import { NavLink, useNavigate } from 'react-router-dom'
import SwitchButton from '../ui/switch-button'
import PlusIcon from '../icons/PlusIcon'
import { getTotalTicketToTest } from '@/services/api-client'
import { isMonitoringAtom } from '@/atom/dashboard'
import { useAtom, useSetAtom } from 'jotai'
import SideBarNotification from './notification/SideBarNotification'
import { isDisplayNotificationWithoutTriggerRef } from '@/atom/notification'
import clsx from 'clsx'
import Cookies from 'js-cookie'
import { isOpenModalAtom } from '@/atom/request'
import {
  BOTTOM_MENU_LIST_DELIVERY,
  BOTTOM_MENU_LIST_MONITORING,
  TOP_MENU_LIST,
  IMenuItem,
  MANAGER_DIRECTOR_ROUTE,
} from './MenuList'
import useDirOwnerProdAccess from '@/hooks/use-dir-owner-prod-access'
import { ROUTES } from '@/configs/route-naming'
import FolderIcon from '../icons/FolderIcon'
import useFeedbackCancellation from '@/hooks/use-feedback-cancellation'

const ACTIVE_MENU_CLASS =
  " flex justify-between bg-neutral-light relative before:contents[''] before:rounded-e-sm before:absolute before:w-1 before:h-6 before:bg-primary-light before:left-0"

export default function SideBar() {
  const navigate = useNavigate()
  const setIsOpenModalRequest = useSetAtom(isOpenModalAtom)
  const { userWantQuit } = useFeedbackCancellation()

  const notificationMenuRef = useRef<HTMLElement>(null)
  const filterDropdownRef = useRef<HTMLElement>(null)
  const notifDivRef = useRef<HTMLElement>()

  const [isDisplayNotification, setIsDisplayNotification] = useAtom(
    isDisplayNotificationWithoutTriggerRef
  )

  const [bottomMenuList, setBottomMenuList] = useState<IMenuItem[]>(
    BOTTOM_MENU_LIST_DELIVERY
  )
  const [_, setIsMonitoring] = useAtom(isMonitoringAtom)
  const [totalTicketToTest, setTotalTicketToTest] = useState()

  const { hasAccess } = useDirOwnerProdAccess()

  const handleSwitchChange = (status: boolean) => {
    setIsMonitoring(status)
    setBottomMenuList(
      status ? BOTTOM_MENU_LIST_DELIVERY : BOTTOM_MENU_LIST_MONITORING
    )
  }
  const fetchTotalTicketToTestData = async () => {
    try {
      const { data } = await getTotalTicketToTest()
      setTotalTicketToTest(data.total)
    } catch (error) {
      throw new Error(`Unexpected error occured ${error}`)
    }
  }

  /**
   *
   * @param e MouseEvent
   * this function watch if notif elt or button trigger was clicked
   */
  const closeNoticationCallback = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement

    const isClickOutsideNotificationMenu =
      !notifDivRef.current?.contains(target)
    const isClickOutsideFilterDropdown =
      !filterDropdownRef.current?.contains(target)

    if (
      isClickOutsideNotificationMenu === true &&
      isClickOutsideFilterDropdown === true
    ) {
      setIsDisplayNotification({ isShow: false })
    }
  }, [])

  function handleSimpleMenu(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    e.preventDefault()
    e.stopPropagation()

    setIsDisplayNotification({ isShow: !isDisplayNotification?.isShow })
  }

  function onCreateRequest() {
    setIsOpenModalRequest(true)
    navigate(ROUTES.CLIENT.REQUEST)
  }

  useEffect(() => {
    fetchTotalTicketToTestData()
  }, [])

  useEffect(() => {
    if (isDisplayNotification?.isShow === true) {
      document.addEventListener('click', closeNoticationCallback)
    }

    return () => {
      document.removeEventListener('click', closeNoticationCallback)
    }
  }, [isDisplayNotification?.isShow])

  return (
    <div className="min-w-[304px] border-r max-h-screen min-h-[calc(100vh-3rem)] border-neutral-dark relative overflow-x-visible">
      {/* ALL NOTIF */}
      <div
        ref={notifDivRef as any}
        className={clsx(
          'absolute h-full border-x transition-all z-10 min-w-[304px] bg-white w-80 ease-in-out',
          isDisplayNotification?.isShow ? '-right-80' : '-right-0 hidden'
        )}
      >
        {isDisplayNotification?.isShow && (
          <SideBarNotification filterDropdownRef={filterDropdownRef} />
        )}
      </div>
      {/* LOGO */}
      <div className="flex items-center py-6 pl-8 gap-x-4 border-b border-b-neutral-dark">
        {Cookies.get('projectUrlAvatar') &&
        Cookies.get('projectUrlAvatar') !== 'null' ? (
          <img
            alt={Cookies.get('projectName')}
            src={Cookies.get('projectUrlAvatar')}
            className="w-6 h-auto border rounded"
          />
        ) : (
          <FolderIcon className="size-8" />
        )}
        <p className="text-primary-dark font-semibold text-xl">
          {Cookies.get('projectName')}
        </p>
      </div>

      {/* ADD REQUEST */}
      <div className="px-8 py-6">
        <Button
          id="btn-add-request"
          onClick={() => onCreateRequest()}
          className="w-full flex gap-2"
        >
          <PlusIcon /> Ajouter une demande
        </Button>
      </div>

      {/* TOP MENU */}
      <ul className="pb-6 border-b border-b-neutral-dark [&>li>a]:transition-all [&>li>a]:hover:cursor-pointer  px-8 flex flex-col gap-2 [&>li>a]:flex [&>li>a]:gap-x-2 [&>li>a]:items-center [&>li>a]:p-2 [&>li>a>span]:text-sm [&>li>a>span]:text-primary-dark [&>li>a>span]:font-medium">
        {TOP_MENU_LIST.map((menuItem, index) => (
          <li
            key={menuItem.title}
            title={menuItem.title}
            className="hover:bg-neutral-light"
          >
            {!menuItem.noRedirect ? (
              <NavLink
                onClick={(e) => userWantQuit(e, menuItem.path)}
                id={`menu-top-${index}`}
                to={menuItem.path}
                className={({ isActive }) =>
                  isActive ? ACTIVE_MENU_CLASS : ''
                }
              >
                <div className="inline-flex gap-2">
                  {menuItem.icon}
                  <span>{menuItem.title}</span>
                </div>
              </NavLink>
            ) : (
              <a
                ref={notificationMenuRef as any}
                href="#fake-link"
                id="menu-notifications"
                onClick={handleSimpleMenu}
              >
                <div className="inline-flex gap-2">
                  {menuItem.icon}
                  <span>{menuItem.title}</span>
                </div>
              </a>
            )}
          </li>
        ))}
      </ul>

      {/* PRODUCT OWNER AND PRODUCT DIR ROUTE */}
      {hasAccess ? (
        <ul className="py-6 border-b border-b-neutral-dark [&>li>a]:transition-all [&>li>a]:hover:cursor-pointer  px-8 flex flex-col gap-2 [&>li>a]:flex [&>li>a]:gap-x-2 [&>li>a]:items-center [&>li>a]:p-2 [&>li>a>span]:text-sm [&>li>a>span]:text-primary-dark [&>li>a>span]:font-medium">
          {MANAGER_DIRECTOR_ROUTE.map((menuItem, index) => (
            <li
              key={menuItem.title}
              title={menuItem.title}
              className="hover:bg-neutral-light"
            >
              {!menuItem.noRedirect ? (
                <NavLink
                  onClick={(e) => userWantQuit(e, menuItem.path)}
                  id={`menu-product-${index}`}
                  to={menuItem.path}
                  className={({ isActive }) =>
                    isActive ? ACTIVE_MENU_CLASS : ''
                  }
                >
                  <div className="inline-flex gap-2">
                    {menuItem.icon}
                    <span>{menuItem.title}</span>
                  </div>
                </NavLink>
              ) : (
                <a
                  ref={notificationMenuRef as any}
                  href="#fake-link"
                  onClick={handleSimpleMenu}
                >
                  <div className="inline-flex gap-2">
                    {menuItem.icon}
                    <span>{menuItem.title}</span>
                  </div>
                </a>
              )}
            </li>
          ))}
        </ul>
      ) : (
        ''
      )}

      {/* BOTTOM MENU */}
      <div className="pt-6 px-8 mb-4">
        <SwitchButton onChange={handleSwitchChange} />
      </div>

      <ul className="pb-6 [&>li>a]:transition-all [&>li>a]:hover:cursor-pointer  px-8 flex flex-col gap-2 [&>li>a]:flex [&>li>a]:gap-x-2 [&>li>a]:items-center [&>li>a]:p-2 [&>li>a>span]:text-sm [&>li>a>span]:text-primary-dark [&>li>a>span]:font-medium">
        {bottomMenuList.map((menuItem, index) => (
          <li
            key={menuItem.title}
            title={menuItem.title}
            className="hover:bg-neutral-light"
          >
            <NavLink
              onClick={(e) => userWantQuit(e, menuItem.path)}
              id={`menu-bottom-${index}`}
              to={menuItem.path}
              className={({ isActive }) =>
                isActive ? ACTIVE_MENU_CLASS : 'flex justify-between'
              }
            >
              <div className="inline-flex gap-2">
                {menuItem.icon}
                <span>{menuItem.title}</span>
              </div>
              {menuItem.name === 'to-test' && totalTicketToTest !== 0 && (
                <span className="rounded-sm w-5 h-5 inline-flex items-center justify-center bg-third">
                  {totalTicketToTest}
                </span>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}
