import useClientSatisfaction, {
  hasFormInteractionAtom,
  OPINION_LIST,
} from '@/atom/client-satisfaction'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import useFeedbackCancellation from '@/hooks/use-feedback-cancellation'
import { cn } from '@/lib/utils'
import { useSetAtom } from 'jotai'
import { CheckCircle2 } from 'lucide-react'
import { useEffect, useState } from 'react'

export default function Form({
  stepKey,
  mainKey,
}: Readonly<{ stepKey: string; mainKey: number }>) {
  const { handleNextSection, handlePrevSection, isFirstStep, stepData } =
    useClientSatisfaction()
  const [currentOpinion, setCurrentOpinion] = useState<string>()
  const setHasFormInteraction = useSetAtom(hasFormInteractionAtom)
  const [comment, setComment] = useState<string>()

  const onNextAction = () => {
    handleNextSection(
      {
        comment: comment ?? '',
        grade: Number(currentOpinion),
        key: stepKey,
      },
      mainKey
    )
  }

  useEffect(() => {
    if (stepData.length) {
      const current = stepData.find((item) => item.key === stepKey)
      if (current) {
        setCurrentOpinion(String(current.grade))
        setComment(current.comment)
      }
    }
  }, [stepData, stepKey])
  return (
    <div className="text-primary-dark">
      <form className="px-14 py-12">
        {mainKey === 0 && (
          <ul className="flex flex-col gap-y-2 text-black text-sm px-6">
            {OPINION_LIST.map((item) => (
              <li key={item.id}>
                <button
                  type="button"
                  onClick={() => {
                    setCurrentOpinion(item.id.toString())
                    setHasFormInteraction(true)
                  }}
                  className={cn(
                    item.id.toString() === currentOpinion
                      ? 'border-primary-light'
                      : '',
                    'flex items-center justify-between py-2 px-3 rounded-md border bg-neutral-light w-full'
                  )}
                >
                  <span className="gap-x-2 flex items-center">
                    {item.icon} {item.desc}
                  </span>
                  {item.id.toString() === currentOpinion && (
                    <CheckCircle2 className="size-5 fill-primary-light text-white" />
                  )}
                </button>
              </li>
            ))}
          </ul>
        )}
        <div className={cn(mainKey === 0 ? 'pt-16' : 'pt-0')}>
          <Label htmlFor="remark">
            Remarque {mainKey === 0 && '(optionnel)'}
          </Label>
          <Textarea
            id="remark"
            rows={7}
            placeholder="Ajouter une remarque"
            className=" bg-neutral-light"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
          />
        </div>
      </form>

      {mainKey === 0 && (
        <div className="flex pt-16 items-center justify-between">
          {!isFirstStep ? (
            <Button
              onClick={() => handlePrevSection(stepKey, 0)}
              className="bg-neutral-dark hover:bg-neutral-dark border shadow-none text-primary-dark"
            >
              Thème précédent
            </Button>
          ) : (
            <div />
          )}
          {currentOpinion ? (
            <Button onClick={() => onNextAction()}>Suivant</Button>
          ) : (
            <Button
              type="button"
              className="bg-neutral-dark hover:bg-neutral-dark shadow-none text-neutral-darker/60 hover:cursor-not-allowed"
            >
              Suivant
            </Button>
          )}
        </div>
      )}
      {mainKey === 1 && (
        <div className="flex pt-16 items-center justify-between">
          <Button
            onClick={() => handlePrevSection(stepKey, 1)}
            className="bg-neutral-dark hover:bg-neutral-dark border shadow-none text-primary-dark"
          >
            Thème précédent
          </Button>
          {comment ? (
            <Button onClick={() => onNextAction()}>Suivant</Button>
          ) : (
            <Button
              type="button"
              className="bg-neutral-dark hover:bg-neutral-dark shadow-none text-neutral-darker/60 hover:cursor-not-allowed"
            >
              Suivant
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
