import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { getProfileInformation, postProfile } from '@/services/api-client'
import { cn, getNameAbbreviation } from '@/lib/utils'
import { useEffect, useState, useRef } from 'react'
import CrossIcon from '@/components/icons/CrossIcon'
import { Input } from '@/components/ui/input'
import { ROUTES } from '@/configs/route-naming'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { useToast } from '@/hooks/use-toast'
import { TOAST_ERROR_MSG } from '@/configs/constants'

interface User {
  id: number
  username: string
  email: string
  first_name: string
  last_name: string
}

interface UserProfile {
  user: User
  subscribed_to_emails: boolean
  subscribed_to_reminders: boolean
  profile_picture: string
}

const MyProfile = () => {
  const { toast } = useToast()
  const [profile, setProfile] = useState<UserProfile | null>(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [showNotification, setShowNotification] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [toUpdateProfile, setToUpdateProfile] = useState({
    first_name: '',
    last_name: '',
  })
  const [isLoadingSubmitChange, setIsLoadingSubmitChange] = useState(false)

  useEffect(() => {
    setToUpdateProfile({
      first_name: profile?.user.first_name ?? '',
      last_name: profile?.user.last_name ?? '',
    })
  }, [profile])

  useEffect(() => {
    getProfileData()
  }, [])

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl)
      }
    }
  }, [previewUrl])

  const getProfileData = async () => {
    try {
      const { data } = await getProfileInformation()

      setProfile(data)
    } catch (error) {
      throw new Error(`Unexpected error: ${error}`)
    }
  }

  const handleUpdateProfile = async () => {
    if (!isFormValid()) return
    setIsLoadingSubmitChange(true)
    try {
      // const
      const { data } = await postProfile({ ...toUpdateProfile })
      setProfile((prevProfile) => ({
        ...prevProfile!,
        user: {
          ...prevProfile!.user,
          first_name: data.user.first_name,
          last_name: data.user.last_name,
        },
      }))
      setShowNotification(true)
    } catch (error) {
      toast(TOAST_ERROR_MSG)
    } finally {
      setIsLoadingSubmitChange(false)
    }
  }

  const handleProfilePictureChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (file && profile) {
      setIsUpdating(true)
      try {
        if (previewUrl) {
          URL.revokeObjectURL(previewUrl)
        }
        const payload = { profile_picture: file }
        await postProfile(payload)
        const newPreviewUrl = URL.createObjectURL(file)
        setPreviewUrl(newPreviewUrl)
      } catch (error) {
        throw new Error(`Error updating profile picture: ${error}`)
      } finally {
        if (!showNotification) {
          setShowNotification(true)
        }
        setIsUpdating(false)
      }
    }
  }

  const handleProfilePictureDelete = async () => {
    if (profile) {
      setIsUpdating(true)
      try {
        const payload = { profile_picture: '' }
        await postProfile(payload)
        setProfile({ ...profile, ...payload })
        if (previewUrl) {
          URL.revokeObjectURL(previewUrl)
          setPreviewUrl(null)
        }
      } catch (error) {
        throw new Error(`Error deleting profile picture: ${error}`)
      } finally {
        setShowNotification(true)
        setIsUpdating(false)
      }
    }
  }

  const isFormValid = () => {
    if (!profile) return false
    const isFieldChanged =
      toUpdateProfile.first_name !== profile.user.first_name ||
      toUpdateProfile.last_name !== profile.user.last_name
    const isFieldsFilled =
      toUpdateProfile.first_name.trim() !== '' &&
      toUpdateProfile.last_name.trim() !== ''
    return isFieldChanged && isFieldsFilled
  }

  const closeNotification = () => {
    setShowNotification(false)
  }

  return (
    <div className="space-y-4 text-primary-dark">
      <h3 className="text-2xl text-primary-dark mb-6">Mon profil</h3>
      {profile ? (
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-4">
            <Avatar className="border border-neutral-dark w-20 h-20">
              <AvatarImage
                src={(previewUrl ?? profile.profile_picture) || ''}
                alt="Profile Picture"
                className="object-contain"
              />
              <AvatarFallback>
                {getNameAbbreviation(
                  `${profile.user.first_name} ${profile.user.last_name}`
                )}
              </AvatarFallback>
            </Avatar>
            <div className="inline-flex gap-2 items-center">
              <Button
                onClick={() => fileInputRef.current?.click()}
                disabled={isUpdating}
              >
                Changer la photo de profil
              </Button>
              <input
                ref={fileInputRef}
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleProfilePictureChange}
              />
              <Button
                className="bg-neutral-light shadow-none hover:bg-neutral-light/10 border border-neutral-dark text-secondary"
                onClick={handleProfilePictureDelete}
                disabled={isUpdating}
              >
                Supprimer la photo de profil
              </Button>
            </div>
          </div>
          {showNotification && (
            <div className="bg-success/10 border-l-4 border-success p-4 flex items-center justify-between">
              <div className="text-primary-dark">
                Les modifications ont été enregistré
              </div>
              <button
                className="hover:cursor-pointer"
                onClick={closeNotification}
              >
                <CrossIcon className="h-5 w-5" />
              </button>
            </div>
          )}
          <div className="flex flex-col gap-2">
            <span>Nom</span>
            <Input
              onChange={(e) =>
                setToUpdateProfile((prev) => ({
                  ...prev,
                  last_name: e.target.value,
                }))
              }
              className="max-w-96"
              value={toUpdateProfile.last_name}
            />
          </div>
          <div className="flex flex-col gap-2">
            <span>Prénom</span>
            <Input
              onChange={(e) =>
                setToUpdateProfile((prev) => ({
                  ...prev,
                  first_name: e.target.value,
                }))
              }
              className="max-w-96"
              value={toUpdateProfile.first_name}
            />
          </div>
          <div className="flex flex-col gap-2">
            <span>E-mail</span>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="max-w-96">
                    <Input
                      disabled
                      value={profile.user.email}
                      className="shadow-none disabled:cursor-default"
                    />
                  </div>
                </TooltipTrigger>
                <TooltipContent side="bottom" align="center" sideOffset={10}>
                  <p className="relative max-w-44">
                    Si vous souhaitez modifier l’e-mail, contactez votre Product
                    Owner
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <div className="py-6">
            <Button
              className={cn(
                isFormValid()
                  ? 'bg-primary-light hover:bg-primary-light text-white'
                  : 'bg-neutral-light hover:bg-neutral-light text-neutral-darker/70 cursor-not-allowed',
                'shadow-none'
              )}
              onClick={handleUpdateProfile}
              disabled={!isFormValid()}
              isLoading={isLoadingSubmitChange}
            >
              Sauvegarder les modifications
            </Button>
          </div>
          <div className="py-6">
            <p className="text-lg border-b font-semibold mt-">
              Sécurité du compte
            </p>
            <div className="flex pt-4 flex-col gap-2">
              <span>Mot de passe</span>
              <div>
                <Button
                  onClick={() => (window.location.href = ROUTES.AUTH.RESET_PWD)}
                  className="bg-neutral-light shadow-none hover:bg-neutral-light/10 border border-neutral-dark text-primary-dark"
                >
                  Changer de mot de passe
                </Button>
              </div>
            </div>
          </div>
          {/* <ContactProduct /> */}
        </div>
      ) : (
        <p>Chargement des données...</p>
      )}
    </div>
  )
}

export default MyProfile
