import { UseFormReturn } from 'react-hook-form'
import { Form, FormControl, FormField, FormItem } from '../ui/form'
import { Textarea } from '../ui/textarea'
import { Button } from '../ui/button'
import Paperclip from '../icons/Paperclip'
import clsx from 'clsx'
import { Label } from '../ui/label'
import { useEffect, useState } from 'react'
import { Cross2Icon, CrossCircledIcon } from '@radix-ui/react-icons'
import { Cross, CrossIcon, File as FileIcon } from 'lucide-react'
import { classifyFile, cn } from '@/lib/utils'
import { ALLOWED_FILE_TYPES } from '@/configs/constants'

interface Props {
  form: UseFormReturn<any>
  onSubmit: (params: any) => Promise<void>
  onInvalid?: () => void
  isLoadSubmit: boolean
  setcurrentPucture: (file: FileList) => void
  isDialog?: boolean
}

export default function CommentForm({
  form,
  onSubmit,
  onInvalid,
  isLoadSubmit,
  setcurrentPucture,
  isDialog,
}: Readonly<Props>) {
  const [previewUrl, setPreviewUrl] = useState<any>(null)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const handleFileChange = (event: any) => {
    console.log(event)
    const file = event.target.files[0]
    console.log(event.target.files)
    if (file) {
      setSelectedFile(file)
      setPreviewUrl(URL.createObjectURL(file))
    }
  }

  const handleRemoveImage = () => {
    setSelectedFile(null)
    setPreviewUrl(null)
    URL.revokeObjectURL(previewUrl)
  }

  useEffect(() => {
    setcurrentPucture(selectedFile)
  }, [selectedFile])
  return (
    <Form {...form}>
      <form
        className={clsx(isDialog ? 'relative pb-8' : '')}
        onSubmit={form.handleSubmit(onSubmit, onInvalid)}
      >
        <FormField
          control={form.control}
          name="content"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="relative w-full">
                  <div
                    className={clsx(
                      isDialog ? 'relative' : 'bottom-0 absolute right-0',
                      ' border bg-neutral-light rounded-md bottom-0 w-full',
                      previewUrl ? 'h-72' : 'h-36'
                    )}
                  >
                    <Textarea
                      id='comment-field-textarea'
                      {...field}
                      value={field.value || ''}
                      placeholder="Ajouter un commentaire ..."
                      className={cn(
                        previewUrl ? 'h-36' : 'h-24',
                        'w-full resize-none border-none shadow-none rounded-md'
                      )}
                    />
                    {previewUrl && (
                      <div className="absolute size-20 px-3">
                        <div className="w-full relative h-full border flex items-center justify-center rounded-md bg-neutral-dark">
                          {classifyFile(selectedFile).picture && (
                            <img
                              src={previewUrl}
                              alt="Preview"
                              className="object-cover w-full h-auto"
                            />
                          )}
                          {classifyFile(selectedFile).video && (
                            <video
                              src={previewUrl}
                              className="w-full m-0 h-[inherit]"
                            />
                          )}
                          {classifyFile(selectedFile).pdf && (
                            <FileIcon size={40} />
                          )}
                          <button
                            className="text-black absolute -top-2 -right-2 size-5 flex justify-center items-center rounded-[50%] bg-primary-light"
                            onClick={handleRemoveImage}
                            type="button"
                          >
                            <Cross2Icon className="size-3 text-white" />
                          </button>
                        </div>
                        <p className='font-normal text-xs w-24 whitespace-nowrap overflow-hidden text-ellipsis'>{selectedFile.name}</p>
                      </div>
                    )}
                  </div>
                </div>
              </FormControl>
            </FormItem>
          )}
        />
        <div
          className={clsx(
            isDialog ? 'right-2 bottom-10' : 'bottom-9 right-10',
            'absolute flex items-center'
          )}
        >
          <Button type="button" size={'sm'} variant={'ghost'}>
            <Label id="attachment-comment-btn" htmlFor="picture" className="hover:cursor-pointer">
              <Paperclip />
            </Label>
            <input
              onChange={handleFileChange}
              className="hidden"
              id="picture"
              type="file"
              accept={ALLOWED_FILE_TYPES}
            />
          </Button>
          <Button
            id='send-btn-comment'
            isLoading={isLoadSubmit}
            size={'lg'}
            type="submit"
            className={clsx(isLoadSubmit ? 'w-auto' : 'w-[87px]')}
          >
            Envoyer
          </Button>
        </div>
      </form>
    </Form>
  )
}
