import { DialogTitle } from '@/components/ui/dialog'
import ActionsTop from './ActionsTop'
import { DialogDescription } from '@radix-ui/react-dialog'
import { useAtom } from 'jotai'
import { RequestAtom } from '@/atom/request'
import {
  formatDateTime,
  generateRandomAvatarColor,
  getFileType,
  getNameAbbreviation,
} from '@/lib/utils'
import { useEffect, useState } from 'react'
import { Button } from '@/components/ui/button'
import useDirOwnerProdAccess from '@/hooks/use-dir-owner-prod-access'
import { editRequest } from '@/services/request.service'
import { useToast } from '@/hooks/use-toast'
import { toastSuccessMsg } from '@/configs/constants'
import { Badge } from '@/components/ui/badge'
import { Separator } from '@/components/ui/separator'
import CommentsIssue from '@/components/commments/CommentsIssue'
import { getRequestComments } from '@/services/comment.service'
import { Comment } from '@/types/ITicket'
import { LoaderCircleIcon } from 'lucide-react'
import Comments from '@/components/commments/Comments'

function CustomNameAvatar({ rawValue }: Readonly<{ rawValue: string }>) {
  if (rawValue) {
    const { bgColor, textColor } = generateRandomAvatarColor(rawValue)
    const firstname = rawValue.split(' ')[0]
    return (
      <span className="text-primary-dark flex items-center gap-x-2 font-normal text-base">
        <span
          className="flex justify-center items-center rounded-full w-7 h-7 text-xs font-medium"
          style={{ backgroundColor: bgColor, color: textColor }}
        >
          {getNameAbbreviation(rawValue)}
        </span>
        {firstname}
      </span>
    )
  } else {
    return null
  }
}

export default function RequestDetails({
  isFullScreen,
  onClose,
}: Readonly<{
  isFullScreen?: boolean
  onClose?: Function
}>) {
  const { hasAccess } = useDirOwnerProdAccess()
  const [requestAtom] = useAtom(RequestAtom)
  const [isLoadReject, setIsLoadReject] = useState(false)
  const [isLoadValidate, setIsLoadValidate] = useState(false)
  const [fileList, setFileList] = useState<string[]>([])
  const { toast } = useToast()
  const [statusStyle, setStatusStyle] = useState({
    bg: '',
    text: '',
  })
  const [categoryStyle, setCategoryStyle] = useState({
    bg: '',
    text: '',
  })

  const handleAction = async (actionType: string) => {
    if (requestAtom) {
      try {
        switch (actionType) {
          case 'accept':
            setIsLoadValidate(true)
            await editRequest({ id: requestAtom.id, approve: true })
            toast(toastSuccessMsg('Demande accepté avec succes!'))
            break
          case 'denie':
            setIsLoadReject(true)
            await editRequest({ id: requestAtom.id, reject: true })
            toast(toastSuccessMsg('Demande rejeté avec succes!'))
            break
        }
      } catch (error) {
        throw new Error('Error: ' + error)
      } finally {
        setIsLoadReject(false)
        setIsLoadValidate(false)
        window.location.reload()
        if (onClose) {
          onClose()
        }
      }
    }
  }

  useEffect(() => {
    if (requestAtom?.files.length) {
      setFileList(() => [
        ...(requestAtom.files as { file: string }[]).map((file) => file.file),
      ])
    }
  }, [requestAtom])
  useEffect(() => {
    const assignStatusColor = () => {
      const { bgColor: text } = generateRandomAvatarColor(
        'color_offset_pre' + requestAtom?.status
      )
      const { bgColor: bg } = generateRandomAvatarColor(
        'color_offset_pre' + requestAtom?.status,
        0.15
      )
      setStatusStyle(() => ({ text, bg }))
    }

    const assignCategoryColor = () => {
      const { bgColor: text } = generateRandomAvatarColor(
        'color_offset_pre' + requestAtom?.category
      )
      const { bgColor: bg } = generateRandomAvatarColor(
        'color_offset_pre' + requestAtom?.category,
        0.15
      )
      setCategoryStyle(() => ({ text, bg }))
    }
    assignStatusColor()
    assignCategoryColor()
  }, [requestAtom])
  return (
    <div>
      {/* dialog top actions */}
      {!isFullScreen && <ActionsTop ticketKey={requestAtom?.id as string} />}
      {/* dialog main content */}
      <div className={!isFullScreen ? 'max-h-[80vh] overflow-y-auto' : ''}>
        <div className="pt-8 pb-6">
          {/* dialog title */}
          {!isFullScreen && (
            <>
              <DialogDescription />
              <DialogTitle />
            </>
          )}
          <div className="w-full grid grid-cols-4">
            <h1 className="text-primary-dark font-semibold w-full min-w-[554px] col-span-3">
              {requestAtom?.title}
            </h1>
            <span className="text-[#717171] font-semibold w-full text-end">
              {requestAtom?.data.jira_issue_id ?? ''}
            </span>
          </div>
        </div>
        <ul className="pb-6 flex [&>] flex-col gap-y-4 [&>li]:flex  max-w-96   [&>li>span:first-child]:text-base [&>li>span:first-child]:font-normal [&>li>span:first-child]:text-[#717171]">
          <li>
            <span className="w-2/3">Demandé par</span>
            <div>
              <CustomNameAvatar
                rawValue={
                  requestAtom?.submitted_by.first_name +
                  ' ' +
                  requestAtom?.submitted_by.last_name
                }
              />
            </div>
          </li>
          <li>
            <span className="w-2/3">Catégorie</span>{' '}
            <span className="text-sm font-medium flex items-center gap-x-2">
              <span
                className="flex justify-center items-center text-sm font-medium px-3 py-1 rounded-full"
                style={{
                  backgroundColor: categoryStyle.bg,
                  color: categoryStyle.text,
                }}
              >
                {requestAtom?.category}
              </span>
            </span>
          </li>
          <li>
            <span className="w-2/3">Statut</span>{' '}
            <span className="text-sm font-medium flex items-center gap-x-2">
              <div
                className="flex justify-center max-w-[160px] items-center text-sm font-medium px-3 py-1 rounded-full uppercase"
                style={{
                  backgroundColor: statusStyle.bg,
                  color: statusStyle.text,
                }}
              >
                {requestAtom?.status}
              </div>
            </span>
          </li>
          <li>
            <span className="w-2/3">Environnement</span>{' '}
            <span className="text-base text-primary-dark">
              {requestAtom?.environment}
            </span>
          </li>
          <li>
            <span className="w-2/3">Date de création</span>{' '}
            <span className="text-primary-dark">
              {formatDateTime(new Date(requestAtom?.created_at as string))}
            </span>
          </li>
        </ul>
        {/* separator */}
        <Separator className="my-4" />
        {/* description */}
        <div className="pt-6">
          <div className="p-6">
            <div className="text-primary-dark font-normal text-base max-h-52 overflow-y-auto">
              {requestAtom?.summary}
            </div>
          </div>
        </div>
        {fileList.length ? (
          <div className="flex flex-col px-6 gap-y-4">
            <div className="text-primary-dark text-xl flex gap-x-2 items-center">
              Pièces jointes{' '}
              <Badge className="hover:bg-neutral-dark bg-neutral-dark w-5 h-5 text-center flex justify-center items-center text-sm p-0 text-neutral-darker">
                {fileList.length}
              </Badge>
            </div>
            <div className="grid justify-start grid-cols-3 gap-4 pb-6 h-40">
              {fileList.map((file) => (
                <div
                  key={file}
                  className="w-auto h-[inherit] [&_video]:h-[inherit] [&_img]:h-full [&_img]:object-cover [&_video]:w-full border rounded"
                >
                  {getFileType(file) === 'image' && (
                    <a href={file} target='_blank'>
                      <img
                        alt={`piece-jointe-${file}`}
                        src={file}
                        className="h-auto w-full"
                      />
                    </a>
                  )}
                  {getFileType(file) === 'video' && (
                    <video src={file} controls />
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
        {/* COMMENT SECTION */}
        {!isFullScreen ? (
          <>
            <Separator className="my-4" />
            <div className="px-4 relative">
              <Comments isFullScreen={false} requestId={requestAtom?.id!} />
            </div>
          </>
        ) : (
          ''
        )}
        {hasAccess ? (
          <div className="">
            <div className="flex justify-end gap-x-4 ">
              <Button
                onClick={() => handleAction('denie')}
                isLoading={isLoadReject}
                variant={'destructive'}
              >
                Refuser
              </Button>
              <Button
                onClick={() => handleAction('accept')}
                isLoading={isLoadValidate}
              >
                Accepter
              </Button>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
