import { choosenRequestIDAtom } from '@/atom/request'
import CrossIcon from '@/components/icons/CrossIcon'
import WarningIcon from '@/components/icons/WarningIcon'
import { Button } from '@/components/ui/button'
import {
  DialogClose,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog'
import { deleteRequest, editRequest } from '@/services/request.service'
import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { useToast } from '@/hooks/use-toast'
import { toastSuccessMsg } from '@/configs/constants'

export default function ActionForm({
  onCancel,
}: Readonly<{ onCancel: Function }>) {
  const actionRequestAtom = useAtomValue(choosenRequestIDAtom)
  const [title, setTitle] = useState({ title: '', desc: '' })
  const [isLoadRequest, setIsLoadRequest] = useState(false)
  const { toast } = useToast()

  useEffect(() => {
    if (actionRequestAtom?.actionType) {
      switch (actionRequestAtom?.actionType) {
        case 'delete':
          setTitle({ title: 'Suppression de la demande', desc: 'supprimer' })
          break
        case 'accept':
          setTitle({ title: 'Validation de la demande', desc: 'accepter' })
          break
        case 'denie':
          setTitle({ title: 'Refu de la demande', desc: 'refuser' })
          break
        case 'archive':
          setTitle({ title: 'Archive de la demande', desc: 'archiver' })
          break
        case 'resolve':
          setTitle({ title: 'Résoudre la demande', desc: 'resoudre' })
          break
      }
    }
  }, [actionRequestAtom])

  const handleAction = async () => {
    setIsLoadRequest(true)
    try {
      switch (actionRequestAtom?.actionType) {
        case 'delete':
          await deleteRequest(actionRequestAtom.id)
          toast(toastSuccessMsg('Demande supprimé avec succes!'))
          break
        case 'accept':
          await editRequest({ id: actionRequestAtom.id, approve: true })
          toast(toastSuccessMsg('Demande accepté avec succes!'))
          break
        case 'denie':
          await editRequest({ id: actionRequestAtom.id, reject: true })
          toast(toastSuccessMsg('Demande rejeté avec succes!'))
          break
        case 'resolve':
          await editRequest({ id: actionRequestAtom.id, resolve: true })
          toast(toastSuccessMsg('Demande résolue avec succes!'))
          break
          break
      }
    } catch (error) {
      throw new Error('Error: ' + error)
    } finally {
      setIsLoadRequest(false)
      onCancel()
    }
  }
  return (
    <div>
      <DialogDescription />
      <DialogTitle />
      <DialogClose className="absolute top-5 right-9 hover:cursor-pointer opacity-70 transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground ">
        <CrossIcon className="h-5 w-5" />
      </DialogClose>
      <div className="flex flex-col justify-center items-center gap-y-2">
        <div className="p-1 bg-secondary/10 rounded-md">
          <WarningIcon />
        </div>
        <p className="text-base font-semibold text-primary-dark">
          {title.title}
        </p>
      </div>
      <p className="text-center my-6 text-primary-dark font-normal text-sm">
        Êtes vous sûre de vouloir {title.desc} cette demande ?
      </p>
      <div className="flex justify-center gap-x-2">
        <Button
          onClick={() => onCancel()}
          className="bg-neutral-dark/30"
          variant={'outline'}
          id=""
        >
          Retour
        </Button>
        {actionRequestAtom?.actionType === 'delete' && (
          <Button id='delete-btn' isLoading={isLoadRequest} onClick={() => handleAction()}>
            Supprimer
          </Button>
        )}
        {actionRequestAtom?.actionType === 'accept' && (
          <Button id='accept-btn' isLoading={isLoadRequest} onClick={() => handleAction()}>
            Accepter
          </Button>
        )}
        {actionRequestAtom?.actionType === 'denie' && (
          <Button id='reject-btn' isLoading={isLoadRequest} onClick={() => handleAction()}>
            Rejeter
          </Button>
        )}
        {actionRequestAtom?.actionType === 'archive' && (
          <Button id='archive-btn' isLoading={isLoadRequest} onClick={() => handleAction()}>
            Archiver
          </Button>
        )}
        {actionRequestAtom?.actionType === 'resolve' && (
          <Button id='resolve-btn' isLoading={isLoadRequest} onClick={() => handleAction()}>
            Résoudre
          </Button>
        )}
      </div>
    </div>
  )
}
