import { useEffect, useState } from 'react'
import { LoaderCircleIcon } from 'lucide-react'
import CustomCard from '@/components/ui/custom-card'
import HeadingTitle from '@/components/ui/heading-title'
import HeadingTag from '@/components/ui/heading-tag'
import CircleRating from '@/components/code-quality/CircleRating'
import GoalIcon from '@/components/icons/Goal'
import QualityRatingBlock from '@/components/code-quality/QualityRatingBlock'
import MetricPanelCard from '@/components/code-quality/MetricPanelCard'
import { getQuality } from '@/services/api-client'
import { QualityMetric, QualityMetricsData } from '@/types/IQuality'
import MonitoringNoConfiguration from '@/components/monitoring-no-confiiguration'
import RepositoryTabs from '@/components/ui/repository-tab'
import {
  METRICS,
  getMetricData,
  getRating,
  getChartData,
  getLastAnalysisDate,
  getTotalScore,
} from '@/lib/utils.quality'

const QUALITY_BLOCKS = [
  {
    title: 'Maintenabilité',
    paragraph: 'Code confus et difficile à maintenir.',
    metricKey: METRICS.MAINTAINABILITY,
    subtitle: 'Mauvaises pratiques',
    dataMetricKey: METRICS.CODE_SMELLS,
  },
  {
    title: 'Fiabilité',
    paragraph:
      'Une erreur de codage qui va casser votre code et qui doit être corrigée immédiatement.',
    metricKey: METRICS.RELIABILITY,
    subtitle: 'Bugs',
    dataMetricKey: METRICS.BUGS,
  },
  {
    title: 'Sécurité',
    paragraph: 'Code pouvant être exploité par des pirates informatiques.',
    metricKey: METRICS.SECURITY,
    subtitle: 'Vulnérabilités',
    dataMetricKey: METRICS.VULNERABILITIES,
  },
  {
    title: 'Examen de sécurité',
    paragraph:
      "Code sensible à la sécurité qui nécessite un examen manuel pour déterminer s'il existe ou non une vulnérabilité.",
    metricKey: METRICS.SECURITY_REVIEW,
    subtitle: 'Points critiques',
    dataMetricKey: METRICS.SECURITY_HOTSPOTS,
  },
]

const CodeQuality: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [branches, setBranches] = useState<QualityMetricsData>([])
  const [qualityData, setQualityData] = useState<QualityMetric[] | []>([])
  const [activeBranchIndex, setActiveBranchIndex] = useState(0)

  const fetchQualityData = async () => {
    try {
      const { data }: { data: QualityMetricsData } = await getQuality()
      setBranches(data)
      /*
        we get empty object when data is empty
      */
      const quality = Array.isArray(data[0]?.quality) ? data[0]?.quality : []
      setQualityData(quality)
    } catch (error) {
      console.error(`Error fetching quality data: ${error}`)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchQualityData()
  }, [])

  const tabItems =
    branches?.map((branch) => ({
      id: branch.branch,
      label: branch.branch,
    })) || []

  const handleTabChange = (index: number) => {
    setActiveBranchIndex(index)
    setQualityData(branches[index]?.quality ?? [])
  }

  if (isLoading) {
    return (
      <div className="h-full flex items-center w-full justify-center">
        <LoaderCircleIcon className="animate-spin w-8 h-8" />
      </div>
    )
  }

  const emptyData =
    qualityData.length > 0
      ? qualityData?.every((item) => item.history?.length === 0)
      : false

  return (
    <div>
      <HeadingTitle label="Qualité du code " />
      {qualityData?.length && !emptyData ? (
        <>
          {branches.length > 0 && (
            <RepositoryTabs
              items={tabItems}
              onChange={handleTabChange}
              activeIndex={activeBranchIndex}
            />
          )}
          <div className="bg-white p-6 border border-neutral-dark">
            <div className="flex flex-col gap-6">
              <CustomCard
                title="Informations générales "
                tag={
                  <HeadingTag
                    label={`Analyse du ${getLastAnalysisDate(qualityData)}`}
                  />
                }
                className="[&>div]:mb-0"
              >
                <div>
                  <div className="grid grid-cols-3 ">
                    <div className="col-span-1">
                      <div className="px-8 py-6 flex flex-col gap-12 justify-center items-center">
                        <h3 className="text-primary-dark text-base">
                          Score total
                        </h3>
                        <CircleRating
                          rating={getTotalScore(qualityData)}
                          className="w-40 h-40 text-5xl"
                        />
                        <div className="bg-neutral-light rounded-md inline-flex items-center gap-2 py-3 px-2">
                          <GoalIcon />
                          <span className="text-primary-dark">
                            L'objectif est d'obtenir un score de A
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="grid grid-cols-4 h-full">
                        {QUALITY_BLOCKS.map((block, index) => (
                          <QualityRatingBlock
                            key={block.title}
                            className={`col-span-2 border-l ${
                              index < 2 ? 'border-b' : ''
                            } border-neutral-dark`}
                            title={block.title}
                            paragraph={block.paragraph}
                            rating={
                              getRating(qualityData, block.metricKey).rating
                            }
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </CustomCard>
              <CustomCard title="Evolution de la qualité du code">
                {QUALITY_BLOCKS.map((block) => (
                  <MetricPanelCard
                    key={block.title}
                    rating={getRating(qualityData, block.metricKey).rating}
                    title={block.title}
                    value={
                      getMetricData(qualityData, block.dataMetricKey).current
                    }
                    previousValue={
                      getMetricData(qualityData, block.dataMetricKey).last
                    }
                    subtitle={block.subtitle}
                    chartData={getChartData(qualityData, block.dataMetricKey)}
                  />
                ))}
              </CustomCard>
            </div>
          </div>
        </>
      ) : (
        <MonitoringNoConfiguration
          context="Qualité du code"
          urlImg="/img/no-config-server-monitoring.png"
          detailList={[
            "Obtenir des informations sur la qualité du code de l'application",
            "Identifier les problèmes de sécurité présents dans l'application",
            'Évaluer la fiabilité du code',
          ]}
        />
      )}
    </div>
  )
}

export default CodeQuality
