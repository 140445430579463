import { ProjectRequest as IProjectRequest } from '@/types/IProjectRequest'
import { useEffect, useState, useMemo } from 'react'

export default function FilterTabs({
  rawData,
  additionalStatusData = [],
  setFilterStatus,
}: Readonly<{
  rawData?: IProjectRequest[]
  additionalStatusData?: { status: string; count: number }[]
  setFilterStatus: Function
}>) {
  const activeMenuClass =
    "after:content[' '] after:absolute after:w-full relative after:border-b-2 after:border-primary-light after:-bottom-[1px] after:left-0"

  const [activeMenu, setActiveMenu] = useState('Tous')

  const [parsedData, setParsedData] = useState<
    { status: string; count: number }[]
  >([])

  const [totalCount, setTotalCount] = useState(0)

  const computeFilter = (data: IProjectRequest[]) => {
    // Count statuses
    const statusCounts = data.reduce((acc: any, item) => {
      const status = item.status
      acc[status] = (acc[status] || 0) + 1
      return acc
    }, {})

    // Convert to an array of filters
    const filters = Object.entries(statusCounts).map(([status, count]) => ({
      status,
      count,
    }))
    const order = [
      'En attente',
      'Acceptée',
      'Résolue',
      'Rejetée',
      'Archivée',
      'Tous',
    ]
    filters.sort((a, b) => order.indexOf(a.status) - order.indexOf(b.status))
    const _totalCount = filters.reduce((acc, curr: any) => acc + +curr.count, 0)

    setActiveMenu(filters[0].status)

    setTotalCount(_totalCount)

    // set "En attente" status first
    const reorderedArray = [
      ...filters.filter((item) => item.status === 'En attente'),
      ...filters.filter((item) => item.status !== 'En attente'),
    ]

    setParsedData(reorderedArray as { status: string; count: number }[])
  }

  useEffect(() => {
    if (rawData && rawData.length) {
      computeFilter(rawData)
    }
  }, [rawData])

  // Update filter when activeMenu changes
  useEffect(() => {
    setFilterStatus(activeMenu)
  }, [activeMenu])

  if (!rawData || !rawData.length) {
    return null
  }

  return (
    <ul className="border-b-2 text-sm [&>li]:cursor-pointer [&>li]:pb-3 flex text-primary-dark gap-x-4 [&>li>button>span]:text-neutral-darker [&>li>button>span]:rounded-md [&>li>button>span]:py-1 [&>li>button>span]:px-2 [&>li>button>span]:bg-neutral-dark">
      {parsedData.map((item) => (
        <li
          key={item.status}
          className={activeMenu === item.status ? activeMenuClass : ''}
        >
          <button onClick={() => setActiveMenu(item.status)}>
            {item.status} <span>{item.count}</span>
          </button>
        </li>
      ))}
      <li className={activeMenu === 'Tous' ? activeMenuClass : ''}>
        <button onClick={() => setActiveMenu('Tous')}>
          Tous <span>{totalCount}</span>
        </button>
      </li>
    </ul>
  )
}
