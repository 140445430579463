import { Button } from '@/components/ui/button'
import PlusIcon from '@/components/icons/PlusIcon'
import ColoredPlusIcon from '@/components/icons/ColoredPlusIcon'
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { getProjectByKey } from '@/services/api-client'
import { useEffect, useMemo, useState } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { getNameAbbreviation } from '@/lib/utils'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog'
import CrossIcon from '@/components/icons/CrossIcon'
import TeamInvitationForm from './TeamInvitationForm'

interface UserProfile {
  id: string
  profile_picture: string
  availability: string
}

export interface TeamItem {
  id: number
  email: string
  first_name: string
  last_name: string
  user_profile: UserProfile
}

const MyTeam: React.FC = () => {
  const [teams, setTeams] = useState<TeamItem[] | null>(null)
  const [loading, setLoading] = useState(true)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const getTeamMember = async () => {
    try {
      const { data } = await getProjectByKey()
      setTeams(data[0].client.users)
    } catch (error) {
      throw new Error(`Unexpected error ${error}`)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTeamMember()
  }, [])

  const members = useMemo(() => teams, [teams])

  const showModal = () => {
    setModalIsOpen(true)
  }

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen)
  }

  const addNewMember = (data: TeamItem) => {
    if (teams) setTeams([...teams, data])
  }

  return (
    <>
      <div className="space-y-4">
        <h3 className="text-2xl text-primary-dark mb-6">Mon equipe</h3>
        <div className="flex justify-between items-center">
          <p className="text-neutral-darker text-sm">
            {members?.length ?? 0} membres
          </p>
          <Button
            id="btn-add-member"
            className="inline-flex gap-2"
            onClick={showModal}
          >
            <PlusIcon /> Ajouter des membres
          </Button>
        </div>
        <div className=" border border-neutral-dark rounded-sm [&>div]:rounded-sm overflow-hidden">
          <div className="relative max-h-[495px] overflow-y-auto">
            <Table className="border-0">
              <TableHeader className="sticky top-0 bg-primary-dark z-50 border-0">
                <TableRow>
                  <TableHead className="text-white text-xs w-1/2 bg-primary-dark [&_th]:text-xs [&_th]:p-4  [&_tr]:hover:bg-primary-dark border border-primary-dark">
                    Nom
                  </TableHead>
                  <TableHead className="text-white text-xs w-1/2 bg-primary-dark [&_th]:text-xs [&_th]:p-4  [&_tr]:hover:bg-primary-dark border border-primary-dark">
                    Adresse e-mail
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={2} className="text-center py-4">
                      Chargement des données...
                    </TableCell>
                  </TableRow>
                ) : (
                  members?.map((team: TeamItem) => (
                    <TableRow key={team.id}>
                      <TableCell className="inline-flex gap-2 items-center">
                        <Avatar className="border border-neutral-dark">
                          <AvatarImage
                            src={team.user_profile.profile_picture}
                            alt={team.first_name}
                          />
                          <AvatarFallback>
                            {getNameAbbreviation(
                              `${team.first_name} ${team.last_name}`
                            )}
                          </AvatarFallback>
                        </Avatar>
                        <span>{team.first_name}</span>
                      </TableCell>
                      <TableCell className="text-left">{team.email}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      <Dialog open={modalIsOpen} onOpenChange={toggleModal}>
        <DialogDescription className="hidden" />
        <DialogTitle className="hidden" />
        <DialogContent className="max-w-[640px] p-0">
          <DialogClose className="absolute top-5 right-9 hover:cursor-pointer opacity-70 transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <CrossIcon className="h-5 w-5" />
          </DialogClose>
          <div className="flex flex-col justify-center items-center gap-y-2 py-6">
            <div className="p-1 rounded-md bg-primary-light/10 w-auto">
              <ColoredPlusIcon />
            </div>
            <span className="text-base font-medium text-primary-dark">
              Ajout des membres
            </span>
          </div>
          <TeamInvitationForm
            onSuccess={addNewMember}
            closeModal={() => setModalIsOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default MyTeam
