import { ProjectRequest as IProjectRequest } from '@/types/IProjectRequest'
import { useEffect, useState, useMemo } from 'react'

export default function FilterTabs({
  rawData,
  additionalStatusData = [],
  setFilterStatus,
}: Readonly<{
  rawData?: IProjectRequest[]
  additionalStatusData?: { status: string; count: number }[]
  setFilterStatus: Function
}>) {
  const activeMenuClass =
    "after:content[' '] after:absolute after:w-full relative after:border-b-2 after:border-primary-light after:-bottom-[1px] after:left-0"

  const [activeMenu, setActiveMenu] = useState('Tous')

  // Calculate combined data using useMemo to avoid unnecessary recalculations
  const combinedStatusData = useMemo(() => {
    const statusMap = new Map<string, number>()

    // Add data from rawData
    if (rawData?.length && rawData[0]?.project?.requests_count) {
      rawData[0].project.requests_count.forEach((item) => {
        statusMap.set(
          item.status,
          (statusMap.get(item.status) ?? 0) + item.count
        )
      })
    }

    // Add additional data
    additionalStatusData.forEach((item) => {
      statusMap.set(item.status, (statusMap.get(item.status) ?? 0) + item.count)
    })

    return Array.from(statusMap.entries()).map(([status, count]) => ({
      status,
      count,
    }))
  }, [rawData, additionalStatusData])

  // Update filter when activeMenu changes
  useEffect(() => {
    setFilterStatus(activeMenu)
  }, [activeMenu])

  if (combinedStatusData.length === 0) {
    return null
  }

  const totalCount = combinedStatusData.reduce(
    (prev, curr) => prev + curr.count,
    0
  )

  return (
    <ul className="border-b-2 text-sm [&>li]:cursor-pointer [&>li]:pb-3 flex text-primary-dark gap-x-4 [&>li>button>span]:text-neutral-darker [&>li>button>span]:rounded-md [&>li>button>span]:py-1 [&>li>button>span]:px-2 [&>li>button>span]:bg-neutral-dark">
      <li className={activeMenu === 'Tous' ? activeMenuClass : ''}>
        <button id="all-btn" onClick={() => setActiveMenu('Tous')}>
          Tous <span>{totalCount}</span>
        </button>
      </li>
      {combinedStatusData.map((item) => (
        <li
          key={item.status}
          className={activeMenu === item.status ? activeMenuClass : ''}
        >
          <button id={item.status} onClick={() => setActiveMenu(item.status)}>
            {item.status} <span>{item.count}</span>
          </button>
        </li>
      ))}
    </ul>
  )
}
