import { useState } from 'react'
import clsx from 'clsx'

interface SwitchButtonProps {
  monitoring?: boolean
  leftValue?: string
  rightValue?: string
  light?: boolean
  id?: string
  onChange?: (isDelivery: boolean) => void
}

const SwitchButton: React.FC<SwitchButtonProps> = ({
  monitoring = true,
  onChange,
  rightValue,
  leftValue,
  light,
  id
}) => {
  const [isDelivery, setIsDelivery] = useState<boolean>(monitoring)
  const handleClick = () => {
    setIsDelivery(!isDelivery)
    if (onChange) {
      onChange(!isDelivery)
    }
  }

  return (
    <div
      aria-hidden
      className={clsx(
        light ? `!bg-neutral-dark` : '',
        'relative inline-flex items-center h-10 w-full cursor-pointer rounded-md bg-primary-dark'
      )}
      onClick={handleClick}
      id={id}
    >
      <div
        id="btn-switch"
        className={clsx(
          'absolute top-1 h-8 w-1/2 rounded-sm transition-all [&>span]:transition-all duration-300 ease-in-out bg-primary-light',
          isDelivery ? 'left-1' : 'left-[calc(50%-0.25rem)]'
        )}
      ></div>

      <span
        className={clsx(
          light && !isDelivery ? '!text-primary-dark' : '',
          'z-10 flex-1 text-center text-white font-medium text-sm'
        )}
      >
        {leftValue ? leftValue : 'Delivery'}
      </span>

      <span
        className={clsx(
          light && isDelivery ? '!text-primary-dark' : '',
          'z-10 flex-1 text-center text-white font-medium text-sm'
        )}
      >
        {rightValue ? rightValue : 'Monitoring'}
      </span>
    </div>
  )
}

export default SwitchButton
