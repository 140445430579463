import DisponibilityCarret from '@/components/icons/DisponibilityCarret'
import WarningIcon from '@/components/icons/WarningIcon'
import { Badge } from '@/components/ui/badge'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { IApplicationStatus } from '@/types/IAppStatus'
import { useEffect, useState } from 'react'

function formatDate(currentDate: Date) {
  const date = currentDate
  const formattedDate = date.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
  const fullDateTime = `${formattedDate}`
  return fullDateTime
}

function DomainState({
  validity,
  expireDate,
  context,
}: Readonly<{ validity: boolean; expireDate: string; context: string }>) {
  const [day, month, year] = expireDate.split('-').map(Number)
  const realDate = new Date(year, month - 1, day)
  expireDate = realDate.toLocaleDateString()
  function computeDateDiff(_expiredDate: Date) {
    const today = new Date()
    const expiredDate = new Date(_expiredDate)
    const diffTime = Math.abs(expiredDate.getTime() - today.getTime())
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  }
  return (
    <div className="px-8 py-6 justify-between flex items-center border-b">
      <div>
        <p className="text-base text-primary-dark">{context}</p>
        <div className="text-base text-primary-dark flex items-center gap-x-2">
          {!validity ? (
            <Badge className="hover:bg-success/10 shadow-none rounded-full bg-success/10 text-success">
              Actif
            </Badge>
          ) : (
            <Badge className="hover:bg-secondary/10 shadow-none rounded-full bg-secondary/10 text-secondary">
              Expiré
            </Badge>
          )}
          <span className="text-sm text-neutral-darker/60">
            {!validity ? 'Expire le' : 'Expiré le'}{' '}
            {formatDate(new Date(expireDate))}
          </span>
        </div>
      </div>
      {validity && new Date() < new Date(expireDate) ? (
        <div className="p-2 rounded text-primary-dark text-sm bg-secondary/10 flex items-center gap-x-2">
          <WarningIcon />{' '}
          <span className="font-semibold">
            {computeDateDiff(new Date(expireDate))} jours
          </span>
          <span>avant expiration</span>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default function Config({
  config,
}: Readonly<{ config: IApplicationStatus }>) {
  const [disponibilityDateList, setDisponibilityDateList] = useState<
    (number | Date | string)[][]
  >([])
  function getAllDatesOfCurrentMonth() {
    const dates = []
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth()
    const lastDay = new Date(year, month + 1, 0).getDate()
    for (let day = 1; day <= lastDay; day++) {
      dates.push(new Date(year, month, day))
    }

    return dates
  }

  useEffect(() => {
    const uptimes = config.uptimes
    console.log(uptimes)
    const today = new Date()
    const mergeDateArr = () =>
      getAllDatesOfCurrentMonth().map((item, index) => {
        if (item > today) {
          return ['N/A', item]
        }
        return [uptimes[index], item]
      })
    setDisponibilityDateList(mergeDateArr())
  }, [config])

  return (
    <div className="flex flex-col w-full">
      <DomainState
        context="Nom de domaine"
        expireDate={config.domain_validity[0]}
        validity={config.domain_validity[1]}
      />
      <DomainState
        context="Certificat SSL"
        expireDate={config.cert_validity[0]}
        validity={config.cert_validity[1]}
      />
      <div className="px-8 py-6 border-b">
        <p className="text-base text-primary-dark">Temps de réponse</p>
        <div className="text-sm text-neutral-darker/60 flex items-center gap-x-1">
          <div className="w-2 h-2 rounded-[50%] bg-success"> </div>{' '}
          {config.response_time} ms
        </div>
      </div>
      <div className="px-8 py-6 justify-between flex items-center">
        <div>
          <p className="text-base text-primary-dark">Disponibilité</p>
          <div className="text-sm text-neutral-darker/60 flex items-center gap-x-1">
            <div className="w-2 h-2 rounded-[50%] bg-success"> </div>{' '}
            {+config['30d_ratio'].toFixed(2)}%
            <span className="text-xs">sur les 30 derniers jours</span>
          </div>
        </div>
        <div className="flex gap-x-[1px] [&_*]:hover:cursor-default">
          {disponibilityDateList.map((item) => (
            <TooltipProvider
              delayDuration={200}
              key={new Date(item[1]).toLocaleDateString()}
            >
              <Tooltip>
                <TooltipTrigger>
                  <DisponibilityCarret />
                </TooltipTrigger>
                <TooltipContent
                  side="top"
                  className="bg-white shadow text-primary-dark"
                  sideOffset={10}
                >
                  <p className="text-sm text-neutral-darker/60">
                    {formatDate(new Date(item[1]))}
                  </p>
                  <p className="text-base text-primary-dark font-normal">
                    {item[0] && item[0] !== 'N/A'
                      ? item[0].toString() + '%'
                      : 'N/A'}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>
      </div>
    </div>
  )
}
