import { getNotificationList } from '@/services/api-client'
import CurrentNotificationsItem from './CurrentNotificationsItem'
import { useEffect, useState } from 'react'
import {
  INotificationResponse,
  INotificationType,
} from '@/types/INotificationResponse'
import VersionNoteLoader from '../loader/version-note-loader'
import EmptyData from '../empty-data'
import clsx from 'clsx'
import { useAtom } from 'jotai'
import { NotificationListAtom } from '@/atom/notification'

const Skeleton = () => (
  <div className="[&_svg]:w-full [&_svg]:h-full">
    <VersionNoteLoader />
    <VersionNoteLoader />
    <VersionNoteLoader />
    <VersionNoteLoader />
  </div>
)

export default function CurrentNotifications() {
  const [notificationList, setNotificationList] =
    useState<INotificationType[]>()
  const [isLoadNotif, setIsLoadNotif] = useState(true)
  const [_, setCurrentNotificationAtom] = useAtom(NotificationListAtom)
  const getNotification = async () => {
    try {
      const { data }: { data: INotificationResponse } =
        await getNotificationList()
      /**
       * only show 4 current notif to fit element in notif card
       */
      setNotificationList([...data.notifications.slice(0, 4)])
      setCurrentNotificationAtom([...data.notifications])
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoadNotif(false)
    }
  }
  useEffect(() => {
    getNotification()
  }, [])
  return (
    <>
      {isLoadNotif ? (
        <Skeleton />
      ) : (
        <div
          className={clsx(
            'flex flex-col h-full',
            !notificationList?.length ? '' : 'relative'
          )}
        >
          {notificationList?.length ? (
            <div className="-mt-2 mb-11 max-h-[175px] overflow-auto">
              {notificationList?.map((notification, idx) => (
                <CurrentNotificationsItem
                  key={notification.content}
                  idx={idx}
                  notification={notification}
                  notifCount={notificationList.length}
                />
              ))}
            </div>
          ) : (
            <div className="absolute right-1/2 w-full bottom-1/2 translate-x-1/2 translate-y-1/2">
              <EmptyData />
            </div>
          )}
        </div>
      )}
    </>
  )
}
