import ChartCard from '@/components/monitoring/ChartCard'
import HeadingTitle from '@/components/ui/heading-title'
import { useEffect, useState } from 'react'
import ColoredGpuIcon from '@/components/icons/ColoredGpu'
import MemoryIcon from '@/components/icons/Memory'
import DiskIcon from '@/components/icons/Disk'
import WifiIcon from '@/components/icons/Wifi'
import { getServerMonitoring } from '@/services/api-client'
import MonitoringNoConfiguration from '@/components/monitoring-no-confiiguration'
import { LoaderCircleIcon } from 'lucide-react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Label } from '@/components/ui/label'
import { TOOLTIP } from '@/configs/constants'

interface IDataPoint {
  date: string
  value: number
}

interface ChartData {
  title: string
  gaugeValue: number
  icon: JSX.Element
  unit: string
  data: IDataPoint[]
  tooltip: string
}

interface Metrics {
  unit: string
  values: number[]
  average: number
}

interface ServerMetrics {
  id: string
  name: string
  metrics: {
    cpu: Metrics
    mem: Metrics
    disk: Metrics
    network: Metrics
    timestamps: string[]
  }
}

const Monitoring: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState('1')
  const [monitoringData, setMonitoringData] = useState<ServerMetrics | null>()

  const prepareChartData = (
    metricType: 'cpu' | 'mem' | 'disk' | 'network'
  ): IDataPoint[] => {
    if (!monitoringData?.metrics.timestamps.length) return []

    const { timestamps, [metricType]: metric } = monitoringData.metrics

    return timestamps.map((timestamp, index) => ({
      date: timestamp,
      value: Math.round(Number(metric.values[index])),
    }))
  }

  const chartData: ChartData[] = [
    {
      title: 'CPU',
      gaugeValue: Number(monitoringData?.metrics.cpu.average.toFixed(2)) || 0,
      icon: <ColoredGpuIcon />,
      unit: monitoringData?.metrics.cpu.unit ?? '%',
      data: prepareChartData('cpu'),
      tooltip: TOOLTIP.CPU,
    },
    {
      title: 'Memory',
      gaugeValue: Number(monitoringData?.metrics.mem.average.toFixed(2)) || 0,
      icon: <MemoryIcon />,
      unit: monitoringData?.metrics.mem.unit ?? '%',
      data: prepareChartData('mem'),
      tooltip: TOOLTIP.MEM,
    },
    {
      title: 'Disk Space',
      gaugeValue: Number(monitoringData?.metrics.disk.average.toFixed(2)) || 0,
      icon: <DiskIcon />,
      unit: monitoringData?.metrics.disk.unit ?? '%',
      data: prepareChartData('disk'),
      tooltip: TOOLTIP.DISK,
    },
    {
      title: 'Bandwidth',
      gaugeValue: Math.round(monitoringData?.metrics.network.average ?? 0),
      icon: <WifiIcon />,
      unit: monitoringData?.metrics.network.unit ?? 'kb/s',
      data: prepareChartData('network'),
      tooltip: TOOLTIP.NET,
    },
  ]

  const fetchMonitoringData = async (daysAgo: string) => {
    try {
      setIsLoading(true)
      const { data } = await getServerMonitoring({ days_ago: daysAgo })
      setMonitoringData(data[0])
    } catch (error) {
      throw new Error(`Error fetching server monitoring data: ${error}`)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchMonitoringData(selectedPeriod)
  }, [selectedPeriod])

  return (
    <div className="flex flex-col gap-4 pb-4 h-full">
      <HeadingTitle>
        <div className="flex justify-between items-center">
          <h1 className="text-primary-dark text-2xl">Surveillance serveur</h1>
          <div className="flex items-center gap-2">
            <Label htmlFor="period" className="text-sm font-medium">
              Période:
            </Label>
            <Select value={selectedPeriod} onValueChange={setSelectedPeriod}>
              <SelectTrigger
                id="period"
                isClassic
                className="w-16 h-8 bg-white"
              >
                <SelectValue placeholder="Période" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="1">1 j</SelectItem>
                <SelectItem value="3">3 j</SelectItem>
                <SelectItem value="5">5 j</SelectItem>
                <SelectItem value="7">7 j</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </HeadingTitle>

      {monitoringData && !isLoading && (
        <div className="flex flex-col gap-4">
          {chartData.map((chart) => (
            <ChartCard
              chartData={chart.data}
              unit={chart.unit}
              key={chart.title}
              title={chart.title}
              gaugeValue={chart.gaugeValue}
              icon={chart.icon}
              infos={chart.tooltip}
            />
          ))}
        </div>
      )}
      {isLoading && (
        <div className="h-full flex items-center w-full justify-center">
          <LoaderCircleIcon className="animate-spin w-8 h-8" />
        </div>
      )}

      {!monitoringData && !isLoading && (
        <MonitoringNoConfiguration
          context="Surveillance serveur"
          urlImg="/img/no-config-server.png"
          detailList={[
            'Détecter des comportements anormaux du serveur',
            "Voir l'espace disponible restante de votre espace disque",
            "Prévenir des pannes potentiels de l'espace disques et de la bande passante",
          ]}
        />
      )}
    </div>
  )
}

export default Monitoring
