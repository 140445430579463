import { ReactNode } from 'react'
import NotAllowedIcon from '../icons/NotAllowedIcon'
import clsx from 'clsx'
import { cn } from '@/lib/utils'

export default function EmptyData({
  msg,
  icon,
  bgIcon,
  className
}: Readonly<{
  msg?: string
  icon?: ReactNode
  bgIcon?: string
  className?: string
}>) {
  return (
    <div className={cn("h-full flex items-center justify-center flex-col gap-y-2", className)}>
      <div className={clsx('p-1 rounded-md', bgIcon ?? 'bg-primary-light/10')}>
        {icon || (
          <NotAllowedIcon className="h-7 w-7 [&_path]:stroke-primary-light" />
        )}
      </div>
      <p className="font-medium text-base text-primary-dark text-center">
        {msg ?? 'Aucune donnée disponible'}
      </p>
    </div>
  )
}
