import { Card, CardContent } from '../ui/card'
export interface RejectedStatusProps {
  testedTicketsCount: number
  testedPOCount: number
  testedClientCount: number
  rejecetedPORatio: number
  rejecetedClientRatio: number
}
const TicketRefusedPercentage = ({
  testedTicketsCount,
  testedPOCount,
  testedClientCount,
  rejecetedPORatio,
  rejecetedClientRatio,
}: RejectedStatusProps) => {
  return (
    <div className="grid grid-cols-3 gap-4">
      <Card className="[&_div]:shadown-none">
        <CardContent className="grid grid-cols-7 h-full p-0">
          <div className="border-r border-neutral-dark col-span-3">
            <div className="flex flex-col items-center justify-center h-full p-2">
              <p className="text-primary-dark text-5xl">{testedTicketsCount}</p>
              <p className="text-sm text-neutral-darker">tickets testés</p>
            </div>
          </div>
          <div className="flex flex-col gap-4 px-8 py-7 mx-auto col-span-4">
            <div>
              <p className="text-primary-dark text-2xl">{testedPOCount}</p>
              <p className="text-xs text-neutral-darker">
                tickets testés par le PO
              </p>
            </div>
            <div>
              <p className="text-primary-dark text-2xl">{testedClientCount}</p>
              <p className="text-xs text-neutral-darker">
                tickets testés par le client
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="flex flex-col justify-center items-center h-full p-4">
          <p className="text-primary-dark text-5xl">{rejecetedPORatio}%</p>
          <p className="text-neutral-darker text-sm">
            de tickets refusés au moins 1 fois par le PO
          </p>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="flex flex-col justify-center items-center h-full p-4">
          <p className="text-primary-dark text-5xl">{rejecetedClientRatio}%</p>
          <p className="text-neutral-darker text-sm">
            tickets refusés au moins 1 fois par le client
          </p>
        </CardContent>
      </Card>
    </div>
  )
}

export default TicketRefusedPercentage
