import ColoredPlusIcon from '@/components/icons/ColoredPlusIcon'
import CrossIcon from '@/components/icons/CrossIcon'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

import {
  LINK_TYPE_LIST,
  TOAST_ERROR_MSG,
  toastSuccessMsg,
} from '@/configs/constants'
import { Separator } from '@/components/ui/separator'
import { useEffect, useState } from 'react'
import { useToast } from '@/hooks/use-toast'
import CancelModalContent from '@/components/modal/cancel-modal-content'
import { postUsefullLink } from '@/services/usefull-link.service'

const formSchema = z.object({
  url: z
    .string()
    .min(1, {
      message: 'Le lien doit contenir au moin 1 caractère.',
    })
    .url({ message: 'Veuillez entrer un url valide' }),
  name: z.string().min(1, {
    message: 'Le nom doit contenir au moin 1 caractère.',
  }),
  category: z.string().optional(),
})
export default function CreateUsefullLinkForm({
  closeModal,
  isInteractOutside,
}: Readonly<{ closeModal: Function; isInteractOutside?: boolean }>) {
  const defaultValues = {
    category: '',
    name: '',
    url: '',
  }
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  useEffect(() => {
    if (isInteractOutside) {
      if (hasChanges) {
        setIsOpenCancelCreaction(true)
      } else {
        closeAllActiveModal()
      }
    }
  }, [isInteractOutside])

  useEffect(() => {
    const subscription = form.watch((values) => {
      setHasChanges(JSON.stringify(values) !== JSON.stringify(defaultValues))
    })
    return () => subscription.unsubscribe()
  }, [form])

  const { toast } = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const [isOpenCancelCreaction, setIsOpenCancelCreaction] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const payload = {
      ...values,
      icon: 'test',
    }
    try {
      setIsLoading(true)
      await postUsefullLink(payload)
      closeModal()
      toast(toastSuccessMsg('Lien ajouté avec succés'))
    } catch (error) {
      toast(TOAST_ERROR_MSG)
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoading(false)
    }
  }

  const closeAllActiveModal = () => {
    setIsOpenCancelCreaction(false)
    closeModal()
  }

  return (
    <div>
      <DialogDescription />
      <DialogTitle />
      <button
        onClick={() =>
          hasChanges ? setIsOpenCancelCreaction(true) : closeAllActiveModal()
        }
        className="absolute top-5 right-9 hover:cursor-pointer opacity-70 transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
      >
        <CrossIcon className="h-5 w-5" />
      </button>
      <div>
        <div className="flex flex-col gap-y-2 justify-start items-center px-0 gap-x-2">
          <div className="p-1 rounded-md bg-primary-light/10 w-auto">
            <ColoredPlusIcon />
          </div>
          <span className="text-base font-medium text-primary-dark">
            Ajouter un lien
          </span>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="pt-0 pb-8 px-0 space-y-8 [&>div]:!mt-4">
              <FormField
                control={form.control}
                name="category"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-primary-dark">
                      Type de lien
                    </FormLabel>
                    <Select onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger isClassic>
                          <SelectValue placeholder="Choisissez un type de lien" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {LINK_TYPE_LIST.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-primary-dark">
                      Nom du lien
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="Ex: Maquettes" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="url"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-primary-dark">
                      Url du lien
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="Ex : https://google.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Separator className="absolute left-0 w-full" />
            <div className="flex justify-end items-center h-20 ">
              <Button
                id="cancel-link"
                variant={'ghost'}
                className="text-neutral-darker hover:bg-transparent"
                onClick={() =>
                  hasChanges
                    ? setIsOpenCancelCreaction(true)
                    : closeAllActiveModal()
                }
                type="button"
              >
                Annuler
              </Button>
              {form.formState.isValid ? (
                <Button
                  id="submit-link"
                  disabled
                  type="submit"
                  isLoading={isLoading}
                >
                  Ajouter le lien
                </Button>
              ) : (
                <Button
                  id="submit-link"
                  disabled
                  type="button"
                  className="hover:cursor-default hover:bg-neutral-dark bg-neutral-dark shadow-none text-neutral-darker"
                >
                  Ajouter le lien
                </Button>
              )}
            </div>
          </form>
        </Form>
      </div>

      {/* CONFIRMATION CLOSE DIALOG */}
      <Dialog
        open={!!isOpenCancelCreaction}
        onOpenChange={() => setIsOpenCancelCreaction(false)}
      >
        <DialogContent className="max-w-[480px]">
          <CancelModalContent onCancel={() => closeAllActiveModal()} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
