import CustomBarChart from '@/components/code-quality/CustomBarChart'
import EvolutionChart from '@/components/sprint-report/EvolutionChart'
import EvolutionLegend from '@/components/sprint-report/EvolutionLegend'
import MixedChart from '@/components/sprint-report/MixedChart'
import MixedChartLegend from '@/components/sprint-report/MixedChartLegend'
import SprintFilter from '@/components/sprint-report/SprintFilter'
import SprintNavigation from '@/components/sprint-report/SprintNavigation'
import CustomCard from '@/components/ui/custom-card'
import HeadingTitle from '@/components/ui/heading-title'
import { getSprintReports } from '@/services/reports.service'
import { ISprintReport, TaskDetail } from '@/types/ISprintReport'
import { LoaderCircleIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import CustomBarTooltip from '@/components/sprint-report/CustomBarTooltip'
import TicketRefusedPercentage, {
  RejectedStatusProps,
} from '@/components/sprint-report/TicketRefusedPercentage'
import DataTable from '@/components/sprint-report/DataTable'
import EmptyData from '@/components/empty-data'

export interface SprintReportType {
  sprint: string
  developed: number
  toTest: number
  completed: number
  developers: number
  ticketsCount: number
  compledtedTicketCount: number
  inProgressTicketCount: number
  testingTicketCount: number
  remainingTicketCount: number
  burndown: ISprintReport['data']['burndown']
  date: string | Date
  timeSpent: ISprintReport['data']['time_distribution']
  remainingSP: number
  total: ISprintReport['data']['total']
  detail: ISprintReport['data']['details']
}

export default function SprintReport() {
  const [sprintReport, setSprintReport] = useState<SprintReportType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentSprint, setCurrentSprint] = useState<string>()
  const [evolutionSprint, setEvolutionSprint] = useState<SprintReportType[]>()
  const [timeSpent, setTimeSpent] = useState<
    { date: string; value: number; tickets: number }[]
  >([])
  const [rejectedStatus, setRejectedStatus] = useState<RejectedStatusProps>()
  const [originalSprintReport, setOriginalSprintReport] =
    useState<SprintReportType[]>()
  const [tableDataset, setTableDataset] = useState<
    { key: string; rejectedPO: number; rejectedClient: number }[]
  >([])

  function handleSliceSprint(v: number) {
    if (originalSprintReport) {
      if (v === 0) {
      }
      const currentSprintReport =
        v === 0
          ? [...originalSprintReport]
          : [...originalSprintReport].slice(-v)
      setSprintReport(currentSprintReport)
    }
  }

  useEffect(() => {
    fetchSprintReportData()
  }, [])

  useEffect(() => {
    const calculateAvarageDirective = (
      totalDay: number,
      totalStorypoint: number
    ) => totalStorypoint / (totalDay - 1)

    if (sprintReport.length && currentSprint) {
      setTimeout(() => {
        const filteredObj = sprintReport.find(
          (item) => item.sprint === currentSprint
        )
        if (filteredObj) {
          setTableDataset(
            filteredObj.detail.map((item) => ({
              key: Object.keys(item)[0],
              rejectedClient: Object.values(item)[0].client_reject_count,
              rejectedPO: Object.values(item)[0].po_reject_count,
            }))
          )
          const arr: any[] = []
          const arrTimeSpent: any[] = []
          const totalDaySpent = Object.keys(filteredObj.burndown).length
          const totalStorypoints =
            +Object.values(filteredObj.burndown)[0].dev_finished +
            +Object.values(filteredObj.burndown)[0].remaining_dev
          const factorAverage = calculateAvarageDirective(
            totalDaySpent,
            totalStorypoints
          )
          Object.entries(filteredObj.burndown).forEach(
            ([_date, value], index) => {
              arr.push({
                directive: Math.round(totalStorypoints - factorAverage * index),
                remainingDev: value.remaining_dev,
                remainingPO: value.remaining_product,
                date: new Intl.DateTimeFormat('fr-FR', {
                  day: 'numeric',
                  month: 'short',
                }).format(new Date(_date)),
              })
            }
          )
          if (filteredObj?.timeSpent) {
            Object.entries(filteredObj?.timeSpent).forEach((item) => {
              arrTimeSpent.push({
                date: item[0],
                value: Math.ceil(item[1].total_time / 3600),
                tickets: item[1].total_count,
              })
            })
            setTimeSpent(arrTimeSpent)
          }
          setEvolutionSprint(arr)
          /**
           * HANDLE REJECTED ACTION
           */
          const {
            total: {
              client_reject_ratio,
              client_test_count,
              po_test_count,
              po_reject_ratio,
            },
          }: SprintReportType = filteredObj
          setRejectedStatus({
            rejecetedClientRatio: Math.round(client_reject_ratio * 100),
            rejecetedPORatio: Math.round(po_reject_ratio * 100),
            testedClientCount: client_test_count,
            testedPOCount: po_test_count,
            testedTicketsCount: client_test_count + po_test_count,
          })
        }
      })
    }
  }, [currentSprint, sprintReport])

  const fetchSprintReportData = async () => {
    try {
      const { data }: { data: ISprintReport[] } = await getSprintReports()
      const mappedList: SprintReportType[] = data
        .map((item) => ({
          completed: getCount('completed', item, true),
          developed: getCount('developed', item, true),
          developers: getDev(item),
          sprint: item.sprint,
          toTest: getCount('toTest', item, true),
          ticketsCount: item.data.details.length,
          compledtedTicketCount: getCount('completed', item),
          inProgressTicketCount: getCount('developed', item),
          testingTicketCount: getCount('toTest', item),
          remainingTicketCount: getCount('remaining', item),
          remainingSP: getRemainning(item),
          burndown: item.data.burndown,
          date: new Date(item.start_date).toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'short',
          }),
          timeSpent: item.data.time_distribution,
          total: item.data.total,
          detail: item.data.details,
        }))
        .reverse()
      setOriginalSprintReport(mappedList)
      setSprintReport(mappedList)
    } catch (error) {
      throw new Error('Unexpected error: ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  const getCount = (
    type: string,
    item: ISprintReport,
    isStoryPoints?: boolean
  ) => {
    const {
      data: { details },
    } = item
    switch (type) {
      case 'toTest':
        return details.reduce((acc, curr) => {
          if (
            Object.values(curr)[0].client_to_test === true ||
            Object.values(curr)[0].product_to_test === true
          ) {
            return !isStoryPoints
              ? acc + 1
              : acc + Object.values(curr)[0].story_points
          }
          return acc
        }, 0)
      case 'completed':
        return details.reduce((acc, curr) => {
          if (Object.values(curr)[0].dev_finished === true) {
            return !isStoryPoints
              ? acc + 1
              : acc + Object.values(curr)[0].story_points
          }
          return acc
        }, 0)
      case 'developed':
        return details.reduce((acc, curr) => {
          if (Object.values(curr)[0].started === true) {
            return !isStoryPoints
              ? acc + 1
              : acc + Object.values(curr)[0].story_points
          }
          return acc
        }, 0)
      case 'remaining':
        return details.reduce((acc, curr) => {
          if (
            Object.values(curr)[0].started === true &&
            Object.values(curr)[0].dev_finished === false &&
            Object.values(curr)[0].product_finished === false &&
            Object.values(curr)[0].client_finished === false
          ) {
            return !isStoryPoints
              ? acc + 1
              : acc + Object.values(curr)[0].story_points
          }
          return acc
        }, 0)
    }
    return 0
  }

  useEffect(() => {
    console.log(sprintReport.length, 'sprintReport')
  }, [sprintReport])

  const getRemainning = (data: ISprintReport) => {
    return Object.values(data.data.burndown).at(-1)?.remaining_dev ?? 0
  }

  const getDev = (data: ISprintReport) => {
    const getAssigneeValueFromObj = (item: Record<string, TaskDetail>) =>
      Object.values(item).map((ticket) => ticket.dev_assignee)
    const devList = data.data.details.map((item) =>
      getAssigneeValueFromObj(item)
    )
    return Array.from(new Set(devList.flat(Infinity).filter(Boolean))).length
  }

  if (isLoading) {
    return (
      <div className="h-full flex items-center w-full justify-center">
        <LoaderCircleIcon className="animate-spin w-8 h-8" />
      </div>
    )
  }

  if (sprintReport?.length === 0) {
    return (
      <div className="h-full">
        <EmptyData msg="Pas de rapport de sprint" />
      </div>
    )
  }

  return (
    <div>
      <HeadingTitle label="Rapports des sprints" />
      <SprintNavigation
        setCurrentSprint={setCurrentSprint}
        sprintData={sprintReport}
      />
      <div className="flex flex-col gap-4">
        <CustomCard
          title="Détail des story points par sprint"
          filter={<SprintFilter onChange={handleSliceSprint} />}
        >
          <div className="grid grid-cols-8 py-6 pr-8">
            <div className="col-span-7">
              <MixedChart selectedSprint={currentSprint} data={sprintReport} />
            </div>
            <div className="col-span-1">
              <MixedChartLegend />
            </div>
          </div>
        </CustomCard>
        <CustomCard title="Evolution du nombre de story points restants pour l’équipe projet">
          <div className="grid grid-cols-8 py-6 pr-8">
            <div className="col-span-7">
              <EvolutionChart data={evolutionSprint} />
            </div>
            <div className="col-span-1">
              <EvolutionLegend />
            </div>
          </div>
        </CustomCard>
        <CustomCard title="Nombre d’heure passé par story point ">
          <div>
            <CustomBarChart
              data={timeSpent}
              tooltip={<CustomBarTooltip />}
              formatYHour={true}
              fullColor
            />
          </div>
        </CustomCard>
        <CustomCard
          className="[&_div]:mb-0"
          title="Pourcentage de refus de ticket par les Product Owner et Client"
        >
          <div className="py-6 px-8">
            <TicketRefusedPercentage {...rejectedStatus!} />
            <div className="py-12">
              <DataTable data={tableDataset} />
            </div>
          </div>
        </CustomCard>
      </div>
    </div>
  )
}
