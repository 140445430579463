import SideBar from '@/components/navigation/SideBar'
import TopBar from '@/components/navigation/topbar/TopBar'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Toaster as Sonner } from '@/components/ui/sonner'
import { Toaster } from '@/components/ui/toaster'
import { ScrollArea } from '@/components/ui/scroll-area'
import clsx from 'clsx'
import useFeedbackCancellation from '@/hooks/use-feedback-cancellation'
import CancelModalContent from '@/components/modal/cancel-modal-content'
import { Dialog, DialogContent } from '@/components/ui/dialog'

export default function DashBoardLayout() {
  const [adjustPadding, setAdjustPadding] = useState('')
  useEffect(() => {
    return () => {
      setAdjustPadding('')
    }
  }, [])
  const handlePaddingAdaptation = (value: string) => {
    setAdjustPadding(value)
  }

  const { isActiveCancelModal, setIsActiveCancelModal, acceptCancellation } =
    useFeedbackCancellation()

  return (
    <>
      <Dialog
        open={!!isActiveCancelModal}
        onOpenChange={() => setIsActiveCancelModal(false)}
      >
        <DialogContent className="max-w-[480px]">
          <CancelModalContent onCancel={acceptCancellation} />
        </DialogContent>
      </Dialog>
      <div className="w-full min-h-[calc(100vh-3rem)]">
        <TopBar />
        <div className="flex w-full h-full">
          <SideBar />
          <ScrollArea
            className={clsx(
              'bg-neutral-light w-full px-6 pt-5',
              adjustPadding || ''
            )}
          >
            <Outlet context={handlePaddingAdaptation} />
          </ScrollArea>
        </div>
      </div>
      <Sonner
        position="bottom-center"
        className="bg-[#171717] shadow-none text-white [&_li]:w-auto"
      />
      <Toaster />
    </>
  )
}
