import { useForm } from 'react-hook-form'
import { ScrollArea } from '../ui/scroll-area'
import { Separator } from '../ui/separator'
import CommentItem from './CommentItem'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'sonner'
import { useEffect, useState } from 'react'
import {
  addRequestComments,
  getRequestComments,
  editRequest,
} from '@/services/api-client'
import { IComment } from '@/types/IComment'
import CommentForm from './CommentForm'
import EmptyData from '../empty-data'
import ProfilLoader from '../loader/profil-loader'
import { cn } from '@/lib/utils'

const formSchema = z.object({
  content: z.string().min(1, { message: '' }),
})

interface Props {
  requestId: string
  isFullScreen?: boolean
}
export default function Comments({
  requestId,
  isFullScreen = true,
}: Readonly<Props>) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      content: '',
    },
  })

  const [isLoadComments, setIsLoadComments] = useState(true)
  const [isLoadSubmitComments, setIsLoadSubmitComments] = useState(false)
  const [commentsList, setCommentsList] = useState<IComment[]>([])
  const [currentPucture, setcurrentPucture] = useState<any>()

  useEffect(() => {
    if (requestId) {
      fetchCommentsList()
    }
  }, [requestId])

  async function fetchCommentsList() {
    try {
      const { data } = await getRequestComments(requestId)
      setCommentsList(data)
      setIsLoadComments(false)
    } catch (error) {
      throw new Error('Unexpected error occured')
    }
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      setIsLoadSubmitComments(true)
      const { data }: { data: IComment } = await addRequestComments({
        request_id: requestId,
        content: values.content,
      })
      const { data: editRequesRes } = await editRequest({
        id: requestId,
        files: currentPucture,
      })
      setCommentsList((prev) => [data, ...prev])
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoadSubmitComments(false)
      form.reset()
    }
  }
  function onInvalid() {
    toast('Un commentaire ne doit pas être vide!')
  }

  return (
    <div className="w-full">
      <p className="pb-5 font-medium text-base text-primary-dark">
        Commentaires
      </p>
      {isFullScreen && <Separator className="absolute left-0" />}
      {/* COMMENT ITEM */}
      {isFullScreen ? (
        <ScrollArea className="h-[76.5vh] pb-[144px]">
          <div className="h-full pr-2">
            {isLoadComments ? (
              <div className="py-4 flex flex-col gap-y-4">
                <ProfilLoader />
                <ProfilLoader />
                <ProfilLoader />
                <ProfilLoader />
              </div>
            ) : (
              <>
                {commentsList.length ? (
                  commentsList.map((comments) => (
                    <CommentItem key={comments.id} comments={comments} />
                  ))
                ) : (
                  <EmptyData
                    className="mb-5"
                    msg="Aucun commentaire disponible"
                  />
                )}
              </>
            )}
          </div>
        </ScrollArea>
      ) : (
        <div className="">
          <div className="h-full pr-2">
            {isLoadComments ? (
              <div className="py-4 flex flex-col gap-y-4">
                <ProfilLoader />
                <ProfilLoader />
                <ProfilLoader />
                <ProfilLoader />
              </div>
            ) : (
              <>
                {commentsList.length ? (
                  commentsList.map((comments) => (
                    <CommentItem key={comments.id} comments={comments} />
                  ))
                ) : (
                  <EmptyData
                    className="mb-5"
                    msg="Aucun commentaire disponible"
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
      <CommentForm
        form={form}
        onSubmit={onSubmit}
        onInvalid={onInvalid}
        isLoadSubmit={isLoadSubmitComments}
        setcurrentPucture={setcurrentPucture}
        isDialog={!isFullScreen}
      />
    </div>
  )
}
