import ChevronLeft from '@/components/icons/ChevronLeft'
import ChevronRight from '@/components/icons/ChevronRight'
import PenIcon from '@/components/icons/PenIcon'
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/configs/route-naming'
import { useSetAtom } from 'jotai'
import {
  isOpenEditModalAtom,
  RequestAtom,
  requestToEditId,
} from '@/atom/request'

export default function ActionsTopFullscreen({
  ticketKey,
  ticketDetail,
}: Readonly<{
  ticketKey: string
  ticketDetail: any
}>) {
  const navigate = useNavigate()
  const setIsOpenModalRequest = useSetAtom(isOpenEditModalAtom)
  const setRequestToEditId = useSetAtom(requestToEditId)
  const setRequestAtom = useSetAtom(RequestAtom)

  const goToRequest = (id: string) => {
    setRequestToEditId(id)
    setIsOpenModalRequest(true)
    setRequestAtom(ticketDetail)
    navigate(ROUTES.CLIENT.REQUEST)
  }
  return (
    <div className="z-50 flex absolute w-full left-0 top-0 h-20 px-6 bg-white justify-between items-center border-b">
      <div className="flex items-center gap-x-2">
        <div className="opacity-40 text-sm transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
          <ChevronLeft />
        </div>
        <div className="opacity-40 text-sm transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
          <ChevronRight />
        </div>
      </div>
      <div className="flex items-center gap-x-2">
        <div
          id='copy-btn'
          aria-hidden
          onClick={() => {
            navigator.clipboard.writeText(
              `${window.location.origin}/request/${ticketKey}`
            )
            toast('Lien copié dans le presse-papiers')
          }}
          className="hover:cursor-pointer opacity-70 text-sm transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          Copier le lien
        </div>
        <button
          id="redirect-btn"
          onClick={() => goToRequest(ticketKey)}
          className="hover:cursor-pointer opacity-70 transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <PenIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  )
}
